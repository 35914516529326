﻿$gap: 32px !default;
$tablet: 769px !default;
$desktop: 960px + 2 * $gap !default;
$wide-screen: 1152px + 2 * $gap !default;
$full-hd: 1344px + 2 * $gap !default;

$breakpoints: ("" 0 "-mobile" $tablet, "-tablet" $tablet "-tablet" $desktop, "-desktop" $desktop "" 99999px);

$column-gap-tiny: 0.25rem !default;
$column-gap-small: 0.5rem !default;
$column-gap: .75rem !default;
$column-gap-medium: 1rem !default;
$column-gap-large: 1.5rem !default;

@media screen and (min-width: 769px) {
    .slider-carousel-container {
        display:flex;
        flex-direction: row;
    }

    .section {
        .column.is-1, .column.is-1-tablet {
            display: flex;
            flex: none;
            width: 8.33333%;
            align-items:center;
        }

        .column.is-10, .column.is-10-tablet {
            display: block;
            flex: none;
            width: 83.33333%;
        }
    }
}



@mixin from($device) {
    @media screen and (min-width: $device) {
        @content;
    }
}

@mixin until($device) {
    @media screen and (max-width: $device - 1px) {
        @content;
    }
}

.swiped {
    overflow-x: hidden;
    overflow-y: hidden;
    margin: -100px;
    padding: 100px;

    & > .swiped-controls {
        flex: none;
        width: 100%;
    }

    & > .swiped-items > .swiped-item {
        box-sizing: content-box;
        flex: none;
        scroll-snap-align: start; /* latest (Chrome 69+) */
        scroll-snap-coordinate: 0% 0%; /* older (Firefox/IE) */
        -webkit-scroll-snap-coordinate: 0% 0%; /* older (Safari) */
        display: block;
        padding: 0 ($column-gap * 2) 0 0;
        min-width: 0;
    }

    & > .swiped-items > .swiped-item:last-child {
        padding-right: 0;
    }

    & > .swiped-items {
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory; /* Chrome Canary */
        scroll-snap-type: mandatory; /* Firefox */
        -ms-scroll-snap-type: mandatory; /* IE/Edge */
        -webkit-scroll-snap-type: mandatory; /* Safari */
        -webkit-scroll-snap-destination: 0% 0%;
        -webkit-overflow-scrolling: touch; /* important for iOS */
        margin: -100px 0 -150px 0;
        padding: 100px 0 150px 0;
        display: flex;
    }

    @each $breakpoint in $breakpoints {
        $breakpoint-name: nth($breakpoint, 1);
        $breakpoint-width: nth($breakpoint, 2);

        & > .swiped-controls [data-swiped-toggle-items#{$breakpoint-name}] {
            color: #CCC
        }

        @for $i from 1 through 12 {
            @include from($breakpoint-width) {
                &[data-swiped-item-count#{$breakpoint-name}="#{$i}"] {
                    & > .swiped-items {
                        scroll-snap-points-y: repeat(percentage(1 / $i));
                    }

                    & > .swiped-items > .swiped-item {
                        width: calc(#{percentage(1 / $i)} - #{$column-gap * 2 * ($i - 1) / $i} + 0.1px);
                    }

                    & > .swiped-controls [data-swiped-toggle-items#{$breakpoint-name}="#{$i}"] {
                        color: #000;
                    }
                }
            }

            @for $j from length($breakpoints)*-1 through -1 {
                $breakpoint-max-name: nth(nth($breakpoints, $j * -1), 3);
                $breakpoint-max-width: nth(nth($breakpoints, $j * -1), 4);

                @if $breakpoint-width < $breakpoint-max-width {

                    @include from($breakpoint-width) {

                        @include until($breakpoint-max-width) { // until mobile

                            &[data-swiped-item-count#{$breakpoint-name}="#{$i}"] {

                                &.swiped-has-preview#{$breakpoint-max-name} {
                                    & > .swiped-items > .swiped-item {
                                        width: calc(#{percentage(1 / $i)} - #{$column-gap * 2 * ($i + 1) / $i});
                                    }
                                }

                                &.swiped-is-gapless#{$breakpoint-max-name} {
                                    & > .swiped-items > .swiped-item {
                                        width: percentage(1 / $i);
                                        margin: 0;
                                        padding: 0;
                                    }
                                }

                                &.swiped-has-gapless-preview#{$breakpoint-max-name} {
                                    & > .swiped-items > .swiped-item {
                                        width: calc(#{percentage(1 / $i)} - #{$column-gap * 2 / $i});
                                        margin: 0;
                                        padding: 0;
                                    }
                                }

                                &.swiped-has-padding#{$breakpoint-max-name} {
                                    & > .swiped-items > .swiped-item {
                                        width: calc(#{percentage(1 / $i)} - #{$column-gap * 2 * ($i + 1) / $i} + 0.1px);
                                        padding-left: $column-gap * 2;
                                        padding-right: 0;
                                    }

                                    & > .swiped-items > .swiped-item:last-child {
                                        padding-right: $column-gap * 2;
                                    }
                                }

                                &.swiped-has-padded-preview#{$breakpoint-max-name} {
                                    & > .swiped-items > .swiped-item {
                                        width: calc(#{percentage(1 / $i)} - #{$column-gap * 2 * ($i + 2) / $i});
                                        padding-left: $column-gap * 2;
                                        padding-right: 0;
                                    }

                                    & > .swiped-items > .swiped-item:last-child {
                                        padding-right: $column-gap * 2;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.swiped-items {
    gap:2%;
}

.swiped > .swiped-items > .swiped-item {
    box-shadow: 0px 0px 10px 1px #666666;
    display: flex;
    flex-direction: column;

    img {
        display: block;
        margin: 0 auto;
    }

    .carousel-title {
        text-align: center;
        margin-bottom: 10px;
        align-self: flex-end;
        width: 100%;
        margin-top: auto;
    }
}



.owl-carousel {
    .owl-item {
        .swiped-item {
            box-shadow: 0px 0px 20px 5px rgba(102, 102, 102, 0.15);
            height: 400px;
            margin-bottom: 20px;
            margin-top: 20px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .carousel-title {
                margin-bottom: 20px;
                text-align: center;
                padding: 0 20px;
            }

            img {
                width: 80%;
                height: auto;
                margin-top: 15px;
                margin-bottom: auto;
            }

            a {
                text-align: center;
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        @media(min-width:600px) {
            .swiped-item {
                height: 300px;
            }
        }

        @media(min-width:1000px) {
            .swiped-item {
                height: 400px;
            }
        }

        @media(min-width:2000px) {
            .swiped-item {
                height: 500px;
            }
        }
    }

    &.data-count-3 {
        .owl-item {
            .swiped-item {
                @media(min-width:600px) {
                    height: 400px;
                }

                @media(min-width:1000px) {
                    height: 500px;
                }

                @media(min-width:2000px) {
                    height: 550px;
                }
            }
        }
    }
}