﻿@import "_mediaqueries.scss";
@import "_color-variables.scss";


.navbar {

    .container {
        .navbar-brand {
            width: auto;
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left:15px;
            .navbar-logo {
                height: 100px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                img{height:100%; max-width:100%;}
            }
            @media(min-width:$break-medium){
                margin-left:0;
            }
        }
    }
}
.search-section{
    display:none;
}


.topnav {
    position: relative;
    padding-top: 15px;
    max-width: 100%;
    width: 1344px;
    margin: 0 auto;
    color: #333;
    height: 48px;
    background: #fff;

    #topnav {
        position: absolute;
        right: 0;
        top: 5px;

        a {
            color: #333;
        }

        li {
            display: table-cell;
            margin: 0 15px;
            padding: 0 15px;
        }

        li.social-icon {
            font-size: 1.8rem;
            margin: 0;
            padding: 0 5px;

            a {
                color: #4d4d4d;
            }
        }
        
        .search-box {
            position: relative;

            input {
                border: 2px solid $Dark;
                padding:0 10px;
            }
        }

        button {
            border: none;
            background: none;
            cursor: pointer;
        }

        .fa-search {
            font-size: 2.4rem;
            line-height: 48px;
            vertical-align: middle;
            color: $Dark;
            position: relative;
            left: -15px;
            z-index: 1;
        }

        .fa-circle {
            position: absolute;
            color: #fff;
            top: 10px;
            right: 28px;
            font-size: 2.0rem;
        }
    }
}


.header-fixed-top {
    background-color: #fff;

    @media (min-width:$break-medium) {
        top: 0;
        position: fixed;
        width: 100%;
        z-index: 1000;
        &.top {
            top: -100px;
            &.active{
                top:0;
                background-color:#fff;
            }
        }
        /*&.white-bg{
            background-color:#fff;
        }*/
    }
}

@media (min-width:$break-large){
    main {padding-top:245px;}
}



.navbar-item {
    padding: 15px;
    &.partner-login{
        text-transform:uppercase;
        margin:0 auto;
    }
    @media(min-width:768px) {
        &.partner-login {
            border-right: 1px solid #4d4d4d;
           a {
                margin-right: 30px;
            }
        }
    }
}
.navbar-item, .navbar-link {
    color: #fff;
    padding: 0;
    font-size: 1.5rem;
    font-family: allotrope, sans-serif;
    font-weight:600;
}
.navbar-item, .navbar-link {
    &.current-page {
        color:$Teal;
        font-weight: 600;
        padding-left: 0;
    }
}
.navbar .navbar-item a {
    &.current-page {
        font-weight: 600;
        padding-left: 0;
        padding-right:0;
        color:$Teal;
    }
}
.navbar {
    .navbar-item {
        display:block;
        &.cta-button {
            &.current-page {
                .navbar-link, a{ border-bottom:none;}
            }
            .navbar-link.current-page{
                border-bottom:none;
            }
        }

    }

}
@media(min-width:1400px){
    .navbar {
        .navbar-item {
            display: flex;
        }
    }
}

.navbar-item.cta-button.current-page, .navbar .navbar-item.cta-button a, .navbar .navbar-item.cta-button .navbar-link.current-page{}

@media( min-width:768px){
    .navbar-item {
        &.active {
            a{
            color:$Teal;}
        }
    }
}


@media screen and (min-width:950px) {
    .navbar > .container {
        display: flex;align-items:center;
    }
    .navbar-end {
        -webkit-box-pack: start;
        justify-content: flex-start;
        margin-left: 0;
        display:flex; align-items:center;
    }
    .navbar-menu { display:flex;align-items:center; width:100%;box-shadow:none;padding-right:15px;background-color:unset;}

    .navbar-item {
        .navbar-link {
            text-transform: uppercase;

            &:hover {
                color: $Teal;
            }
        }

        &.cta-button {
            .navbar-link {
                &:hover {
                    border-bottom: none;
                }
            }
        }

        .navbar-link:not(.is-arrowless) {
            padding-right: 0;
            margin-right: 25px;
            padding-left: 0;

/*            @media(min-width:$break-large) {
                margin-right: 2.5em;
            }*/
        }
    }

    .navbar-dropdown.is-boxed, .navbar.is-spaced .navbar-dropdown {
        border-radius: 0;
        border-top: none;
        /*box-shadow: 0 8px 8px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1);*/
        box-shadow:none;
        padding-top:0;
        padding-bottom:0;
        display: block;
        opacity: 0;
        pointer-events: none;
        top: 100%;
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
        -webkit-transition-duration: 86ms;
        transition-duration: 86ms;
        -webkit-transition-property: opacity, -webkit-transform;
        transition-property: opacity, -webkit-transform;
        transition-property: opacity, transform;
        transition-property: opacity, transform, -webkit-transform;
        background-color: #fff;
        font-size: 1.4rem;
        margin-top:10px;
    }

    .navbar-item.has-dropdown:hover .navbar-dropdown .navbar-link, .navbar-item.has-dropdown.is-active .navbar-dropdown .navbar-link {
        background-color: inherit;
        color: #4d4d4d;
    }

    .navbar-item.has-dropdown > .navbar-link {
        padding-left: 0;
        /*border-bottom:2px solid transparent;*/
    }
    .navbar-item.has-dropdown.is-active > .navbar-link {
        background: #fff;
        border-bottom: 2px solid #006db6;
        color: #006db6;
    }
    .navbar-item.cta-button-red {
        width:auto;
        margin-left:15px;
    }
    .navbar-item.cta-button-red > .navbar-link{
        color:#fff;
    }

    .navbar.is-spaced .navbar-link {
        border-radius: 0;
    }

    .navbar-dropdown {
        /*top: 168px;*/
        .navbar-item {
            border-bottom: 1px solid #d6d6d6;
            border-right:1px solid #d6d6d6;
            box-sizing: border-box;
            -webkit-box-sizing: border-box;
            &:last-child{border-bottom:none;}
            a {
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                /*border-left:2px solid transparent;*/
            }

            &:hover {
                background: #e6e6e6;

                a {
                    border-left: 2px solid #006db6;
                }
            }
            /*display: table-cell;*/
        }
    }
}

@media screen and (min-width:1024px){
    .navbar-menu {width:auto;}
}

.navbar-link.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, a.navbar-item.is-active, a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover{
    background-color:transparent;color:unset;
}

@media screen and (min-width: 1024px) {
    .navbar-item.has-dropdown.is-active .navbar-link, .navbar-item.has-dropdown:focus .navbar-link, .navbar-item.has-dropdown:hover .navbar-link 
    {
        background-color:transparent;
    }
                                       }





    // Hiding the dropdown arrows for top nav with dropdown... in case we ever want them back, just remove these two lines.
    .navbar-link:not(.is-arrowless):after {
        border: none;
    }

    .navbar-link:not(.is-arrowless):after, .select:not(.is-multiple):not(.is-loading):after {
        border: none;
    }


.hide-small {
    display: none;
}
@media(min-width:1400px){
    .hide-small{
        display:inline-block;
    }
}


/* NEW NAV SETTINGS */


.nav-background {
    position: fixed;
    top:-100%;
    width: 100%;
    /*background: #EBEBEB;*/
    z-index: 1001;
    transition: ease 1s all;
    right: 0;
    left: 0;
    padding-top: 0px;
    overflow-y: scroll;
    opacity: 0;
    z-index: -100;

    @media(min-width:$break-medium) {
        top: 0;
        overflow-y: auto;
    }

    &.active {
        opacity: 1;
        bottom: 0;
        top: 0;
        overflow:hidden;
        z-index:1000;
    }

    .close {
        color: #000;
        position: absolute;
        top: 0;
        cursor: pointer;
        font-size: 4rem;
        right: 20px;
        z-index: 100;

        @media(min-width:$break-medium) {
            right: 55px;
        }
    }

    .nav-preview-header {
        color: #000;
        font-weight: normal;
        font-size: 5rem;
        text-transform: uppercase;
        display: inline-block;
        width: auto;
        font-family: 'Oswald';
        position: relative;
        padding-right: 20px;
        margin-right: 20px;
        z-index: 100;
        text-align: right;
        max-width:30%;
        .mobile-hide {
            display: none;
        }

        @media(min-width:1060px) {
            width: auto;
            font-size: 3.6rem;
            border-bottom: none;
            text-align: right;
            white-space: nowrap;
            max-width: 20%;
            padding-left: 0;
            margin-left: 0;
            margin-bottom: 0;

            .mobile-hide {
                display: inline-block;
            }
        }

        @media screen and (min-width:1400px) {
            font-size: 5rem;
            .hide-small{
                display:inline-block;
            }
        }
    }


    .subnav-item {
        width: 100%;
        float: none;
        margin-bottom: 0;

        @media(min-width:$break-medium) {
            width: 100%;
            float: left;
        }
    }

    .sub-nav {
        @media(min-width:$break-medium) {
            padding-top: 20px;
        }

        .sub-nav-link {
            color: #000;
            font-family: 'Oswald';
            display: block;
            font-size: 2.8rem;
            cursor: pointer;
            z-index: 100;
            position: relative;
            box-sizing: border-box;
            margin-left: 1px;
            margin-top: 1px;
            margin-bottom: 1px;
            margin-right: 1px;

            &.active {
            }
        }
    }

    .sub-nav-preview-image {
        display: none;
        padding: 30px;
        background-color: #272727;
        border-radius: 10px;
        color: #fff;
        position: relative;
        left: -45px;

        @media(min-width:$break-medium) {
            &.active {
                display: block;
            }
        }

        img {
            margin: 0 auto;
            display: block;
        }

        .sub-nav-preview-image-headline {
            padding: 10px 0;
            font-size: 3rem;
            font-weight: 600;
        }

        .sub-nav-preview-image-summary {
            font-size: 2rem;
        }
    }

    .navSection {
        opacity: 0;
        position: absolute;
        right: -100%;
        /*top: 100px;*/
        transition: ease all 1s;
        padding-top: 80px;
        width: 100%;
        height: 100%;



        .left-slash {
            position: absolute;
            top: 0;
            bottom: 0;
            right: -200px;
            left: -5%;
            background: #EBEBEB 0% 0% no-repeat padding-box;
            transform: skew(-3deg);
        }

        &.active {
            right: 0;
            opacity: 1;
            transition: ease all 1s;
        }

        @media(min-width:$break-medium) {
            width: 60%;
        }

        @media(min-width:$break-large) {
            width: 40%;
        }
    }

    .nav-section-media {
        position: absolute;
        /*top: 100px;*/
        opacity: 0;
        transition: ease all 1s;
        background-color: #fff;
        height: 100%;
        left: -100%;

        .right-slash {
            position: absolute;
            top: 0;
            bottom: 0;
            right: -5%;
            left: 0;
            background: #fff;
            transform: skew(-3deg);
        }

        img {
            position: relative;
            z-index: 10;
            width: 100%;
        }

        &.active {
            transition: ease all 1s;
            opacity: 1;
            left: 0;
        }

        @media(min-width:$break-medium) {
            width: 36%;
        }

        @media(min-width:$break-large) {
            width: 56%;
        }
    }
}

.topnav{
    display:none;
    input{ margin-top:0;width:auto;}

    @media(min-width:$break-medium){
        display:block;
    }
}
.header-fixed-top {
    transition:1s ease all;

    .reverse-microsite-logo {
        display: none;
        width:300px;
    }

    .microsite-logo {
        display: block;
        width:300px;
    }
}
.header-fixed-top.active {
    background-color: #fff;
    .reverse-microsite-logo{
        display:block;
    }
    .microsite-logo{display:none;}
    .topnav {
        color: #fff;
        background-color: #4d4d4d;
        #topnav a{ color:#fff;}
    }
    /*.navbar { .container {.navbar-logo{ width:120px;}} }*/
}

.navbar {

    .reverse-logo {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-repeat: no-repeat;
        transition: all 1s ease;
        background-position: left;
    }

    @media(max-width:950px) {
        position: fixed;
        right: 0;
        left: 0;
        z-index: 1000;

        .navbar-menu {
            display: flex;
            left: -100%;
            right: 100%;
            position: fixed;
            transition: all 1s ease;
            background-color: transparent;
            height:100%;
        }
    }

    transition: 500ms background-color ease;

    &.active {
        z-index: 1001;
        background-color: #4d4d4d;
        box-shadow: none;
        /*border-bottom: 2px solid #707070;*/
        position: fixed;
        right: 0;
        left: 0;
        /*.navbar-item, .navbar-link {
            color: #aeafb3;
            padding: 0;
            &.current-page{border-bottom:none;}
        }*/
        @media(max-width:950px) {
            &.has-shadow {
                background-color: #fff;
                transition: 500ms background-color ease;
                border-bottom: none;
            }

            .navbar-end {
                width: 100%;
                min-height: 800px;
            }

            .navbar-menu {
                left: 0;
                right: 0;
                transition: all 1s ease;
                height: 100%;
                background-color: #fff;
                overflow-y: scroll;
            }

            .navbar-item, .navbar-link {
                color: #000;
                padding: 10px 30px;
                display: block;
                font-size: 2rem;
                cursor: pointer;
                z-index: 100;
                position: relative;
                box-sizing: border-box;
                margin: 1px;
                min-height: 0;
                text-align: center;
                &.partner-login{
                    padding:0;
                }

                &.cta-button {
                    margin: 20px auto;
                    padding: 0;
                    border-radius: 100px;
                    text-decoration: none !important;
                    min-height: 0;

                    .navbar-link {
                        min-height: 0;
                    }
                }

                &.has-dropdown {
                    width: 100%;
                    display: block;

                    .navbar-link {
                        width: 100%;
                        padding: 0;
                    }
                }

                &.has-dropdown > .navbar-link {
                    text-transform: capitalize;
                    /*border-bottom: 1px solid #666;*/
                    font-size:2rem;
                }

                .navbar-link {
                    padding: 10px 0;
                }
            }
        }

        .navbar-logo {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .reverse-logo {
            /*opacity: 1;
            transition: all 1s ease;*/
        }

        .navbar-menu {
            display: flex;
        }
        /*.logo {
            opacity: 0;
        }*/
        .navbar-link.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, a.navbar-item.is-active, a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover {
            background-color: transparent;
            color: #aeafb3;
        }

        .cta-button-blue {
            .navbar-link {
                color: #fff;
            }

            .navbar-link:hover {
                color: #006db6;
            }
        }
        /*.navbar-item {
            padding-left: 0;
            margin-left: 20px;

            &.cta-button-blue {
                box-shadow: none;
                border: 2px solid #fff;
                padding: 6px 17px;
                &:hover{color:$Blue;
                        .navbar-link{ color:$Blue;}
                }
            }

            .navbar-link {
                padding-left: 0;
            }

            &.active {
                a {
                    color: #000;
                    z-index: 10000;
                    border-bottom: #fff solid 2px;

                    &:hover {
                        color: #aeafb3;
                        border-bottom-color: #aeafb3;
                    }
                }
            }
        }*/
    }
}


.submenu-button {
    opacity: 0;
    position: fixed;
    top: 20px;
    font-size: 2rem;
    right: 100%;
    transition: 1s ease all;
    color: $White;
    line-height:3rem;
    vertical-align:middle;
    .fa-chevron-left{font-size:4rem;vertical-align:middle;}
    &.active {
        opacity: 1;
        right: 20px;
    }
    &:hover{color:$Light;}
    @media(min-width:$break-medium){
        display:none;
    }
}
.navbar-toggle{position:fixed;
               right:20px;
               font-size:4rem;
               display:block;
               color:#fff;
               opacity:1;
               cursor:pointer;
               &.active{ color:#efefef;}
               &:hover{color:#efefef;}
               &.hide-me{
                   opacity:0;
               }
               @media(min-width:950px){
                   display:none;
               }
}


@media screen and (min-width: 1024px){ 
    .navbar.is-spaced {
    padding: 0;}
}

.nav-vertical-slash {
    background-color: #000;
    width: 1px;
    height: 100px;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    transform: rotate(5deg);

}

.navbar-link {
    .nav-vertical-slash{
        height:100%;
        right:20px;
        @media(min-width:$break-medium){
            display:none;
        }
    }
}

.mobile-dropdown {
    position: relative;
    width: 100%;

    a {
        color: #000;

        &.teal-text {
            color: #04ccc3;
        }
    }

    .sub-nav-link {
        width: 100%;
        position: relative;
        display: block;
/*        border-bottom: 2px solid #fff;
*/        padding-bottom:5px;
        &:hover {
  /*          border-bottom: 2px solid $Teal;
  */      }
    }

    @media(min-width:$break-medium) {
        display: none;
    }
}

@media(max-width:950px){
    .navbar-item{
        text-transform:capitalize;
    }
    .navbar-item.has-dropdown {
        .navbar-link {
            display: none;
        }

        .mobile-dropdown {
            .sub-nav-link {
                margin-bottom: 10px;
                margin-top: 10px;

            }
        }
    }
}


.mobile-dropdown.blog-nav {
    padding: 20px 20px 0px 20px;
    min-width: 300px;

    .teal-text {
        font-family: 'Roboto', sans-serif;
    }
}

.teal-section {
    width: 100%;


    .blog-snip {
        font-family: 'Roboto',sans-serif;
        color: black;
        font-size: 12px;
        padding: 10px 0;
        display: none;
    }
}

@media(min-width:950px){

    .navbar-item {
        a {
            &:hover {
                text-decoration: none;
            }
        }

        .mobile-dropdown {
            top: -500px;
            display: block;
            position: absolute;
            background: #fff;
            padding: 20px;
            border-radius: 0;
            transition: ease all 1s;
            min-width: 200px;

            a {
                border-bottom: none;
                padding-bottom: 5px;

                &.sub-nav-link {
    /*                border-bottom: 2px solid #888;
    */                margin-bottom: 5px;
/*                    &:last-child{ border-bottom:2px solid #fff;}
*/                    &:hover {
  /*                      border-bottom: 2px solid $Teal;
  */                      text-decoration: none;
                          color:$Teal;
                    }
                }
            }
        }
    }
    .navbar-item.active {
        .mobile-dropdown {
            top: 30px;
            box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.20);
            /*            &:before {
                content: "\A";
                top: -15px;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 15px solid #fff;
                position: absolute;
            }
*/
        }
    }


    .mobile-dropdown a.sub-nav-link {
        color: #888888;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        &.teal-text{
            color:$Teal;
        }
    }

    .teal-section {
        background-color: #dffcfa;
        position: relative;
        left: -20px;
        padding: 20px;
        width: 300px;
        top: 0;
        border-top-right-radius: 20px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .blog-snip {
            display: block;
        }
    }

    .mobile-dropdown.blog-nav {
        padding: 20px 20px 0px 20px;
        min-width: 300px;

        .teal-text {
            font-family: 'Roboto', sans-serif;
        }
    }

    }




.header-fixed-top, .navbar, .navbar.has-shadow, .topnav {
    background: $DarkBlue;
    box-shadow: none;

    @media(min-width:768px) {
        background: $DarkBlue;
        box-shadow: none;
    }
}

.header-fixed-top {
    .navbar {
        height: 100px;
        padding-left: 20px;
        padding-right: 20px;

        @media(min-width:1400px) {
            padding-left: 0;
            padding-right: 0;
            max-width: 1400px;
            margin: auto;
        }
    }

    &.active {
        .navbar {
            background-color: #fff;
            transition: 1s ease background-color;
            box-sizing: content-box;
        }
    }
}


.footer-nav {
    .nav-footer-header {
    color:$DarkBlue;
    text-transform:uppercase;
    }

    .sub-nav {
        .subnav-item.bold {
            a {
                color: $DarkBlue;
                text-transform:uppercase;
            }
        }
    }
}