
// 2. Set your own initial variables
$grey-section:#f8f9fa;
$grey-light: #dbdbdd;
$grey-dark: #aeafb3;
$grey-darker: #454547;
$pink: #e89d93;
$yellow: #eec71a;
$grey-lighter: #e8e8e8;

// 3. Set the derived variables

$dark: $grey-darker;
$text: $grey-darker;
$card-shadow: .0 0.25rem 0.125rem 0 rgba(0,0,0,0.1);
$card-footer-border-top: 1px solid $grey-lighter;
$footer-background-color: $grey-darker;
$body-background-color: $grey-section;
$section-padding-medium: 6rem 1.5rem;
$panel-item-border: 0;
$panel-heading-radius:0;



.panel {
    box-shadow: $card-shadow;
    background: $white;
}




// OVERRIDES
// NAVBAR
.navbar {
    &-brand {
        margin-right:2em;
        .navbar-item {
            font-weight:700;
            font-size:1.1rem;
            span {
                background: $grey-lighter;
                border-radius: 3em;        
            }
        }
    }
}

.navbar-item {
    color: $grey-darker;
}