﻿
@import "_mediaqueries.scss";
@import "_color-variables.scss";

body {
    font-size: 10px;
    font-family: hero-new, sans-serif;
    font-weight: 600;
    font-style: normal;
}
strong{
    font-weight:800;
}

.allotrope-font {
    font-family: allotrope, sans-serif;
    font-weight: 600;
    font-style: normal;
}

.font-18{
    font-size:1.8rem;
    line-height:1.5;
}

.font-24{
    font-size:2.4rem;
    line-height:1.75;
}
.font-30{
    font-size:3rem;
    line-height:1.5;
}
.font-36{
    font-size:2.8rem;
    line-height:3.4rem;
}
.font-42{
    font-size:4.2rem;
    line-height:4.8rem;
}
.font-48{
    font-size:3.6rem;
    line-height:1.2;
}
.font-60{
    font-size:3.6rem;
    line-height:1.2;
}



@media(min-width:$break-medium){
    .font-48 {
        font-size: 4.8rem;
        line-height: 1.2;
    }

    .font-60 {
        font-size: 6rem;
        line-height: 1.2;
    }


}

.regular-font{
    font-weight:400;
}

.thin-font{
    font-weight:300;
}

.title {
    font-weight: 600;
}

.title.bold, .bold {
    font-weight: 700;
}

.title.light {
    font-weight: 300;
}


.black-text{
    color:black;
}


a {
    color: $Teal;

    &:hover {
        text-decoration: underline;

        .light-grey-text {
            color: $Teal;
        }
    }
}

.title-align-center{
    h1,h2,h3,h4,h5{
        text-align:center;
    }
}