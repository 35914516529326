﻿body {
}



.padding-left-20{
    padding-left:20px;
    display:block;
}
.padding-left-40 {
    padding-left: 40px;
    display: block;
}
.padding-left-60 {
    padding-left: 60px;
    display: block;
}
.padding-right-20 {
    padding-right: 20px;
    display: block;
}

.padding-right-40 {
    padding-right: 40px;
    display: block;
}

.padding-right-60 {
    padding-right: 60px;
    display: block;
}
.padding-top-20 {
    padding-top: 20px;
    display: block;
}
.padding-top-40 {
    padding-top: 40px;
    display: block;
}
.padding-top-60 {
    padding-top: 60px;
    display: block;
}
.padding-b-20 {
    padding-bottom: 20px;
    display: block;
}
.padding-b-40 {
    padding-bottom: 40px;
    display: block;
}
.padding-b-60 {
    padding-bottom: 60px;
    display: block;
}

@media(min-width:768px){
    .hero-banner.section{
        justify-content:center;
    }

    .is-8.left2thirds {
        .hero-banner-content {
            display: flex;
            justify-content: flex-end;
        }

        .subhead {
            width: 60%;
            padding-right: 50px;
        }
    }
    .section.leftShadow {
        box-shadow: -10px 0 5px -7px #999;
        height: 100%;
        padding-left:30px;
    }

    .left-divider {
        height: 130px;
        position: absolute;
        width: 1px;
        background-color: black;
    }

}

.hero-body{
    align-items:center;
    &.align-top{ align-items:flex-start;}
    &.align-mid{ align-items:center;}
    &.align-bottom{ align-items:flex-end;}
}