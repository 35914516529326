﻿@import "_mediaqueries.scss";
@import "_color-variables.scss";


.color-highlight-section {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    .left-arrow, .right-arrow {
        position: absolute;
        height: 150%;
    }

    .right-arrow {
        right: 0;
    }

    .grey-arrow {
        display: none;
    }

    .content-box {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 1200px;
        width: 90%;
        margin: 0 auto;
        padding: 60px 30px;

        .content-text {
            display: block;
            width: 100%;
            z-index: 10;

            &.flex-this {
                @media(min-width:768px) {
                    display: flex;
                    flex-direction: row;
                }

                .buttons {
                    a {
                        font-size: 1.4rem;
                        background: none;
                        color: $Teal;
                        border: 1px solid $Teal;
                        margin-left: auto;
                        margin-right: auto;

                        &:hover {
                            color: #fff;
                            background-color: $Teal;
                        }

                        @media(min-width:$break-medium) {
                            margin-left: 50px;
                            margin-right: 50px;
                            min-width: 300px;
                        }
                    }
                }
            }
        }

        p {
            width: 100%;
            z-index: 10;
        }
    }

    &.right-x {
        .right-arrow {
            left: 100%;
        }

        .left-arrow {
            right: 0;
        }
    }

    &.left-x {
        .right-arrow {
            left: 0;
        }

        .left-arrow {
            right: 100%;
        }

        .content-text.flex-this {
            max-width: 80%;
            left: 20%;
            display: block !important;
            position: relative;

            .buttons {
                width: 100%;

                a {
                    margin-left: 0;
                }
            }
        }

        &.has-background-white {
            .content-text.flex-this {
                .buttons {
                    a {
                        background-color: $Teal;
                        color: #fff;
                    }
                }
            }

            .white-arrow {
                display: none;
            }

            .grey-arrow {
                display: block;
            }
        }
    }

    .left-arrow, .right-arrow {
        display: none;
    }

    @media(min-width:$break-medium) {
        .left-arrow, .right-arrow {
            display: flex;
        }
    }
}


