﻿
@import "_mediaqueries.scss";
@import "_color-variables.scss";

.creditUnion-body{
    background-color:#fff;
}

.creditUnion-header {
    display: flex;
    vertical-align: middle;
    margin: 20px auto;
    padding: 70px;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: 1;
    top:-20px;
    background:#efefef;
    .left-side {
        width: 50%;
        text-align: right;
        text-transform: uppercase;
    }

    .right-side {
        width: 50%;
        text-align: left;
        text-transform: uppercase;
    }
}
.creditUnion-Form {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    padding: 50px;
    background-color: #fff;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.20);
    position:relative;
    top:120px;
    z-index:2;
    margin-bottom:100px;
    .creditUnion-Logo {
        height: auto;
        max-width: 300px;
        margin: 20px auto;
        overflow: hidden;
        object-fit: contain;

        img {
            width: 100%;
        }
    }

    .upload-wrapper {
        width: 100%;
    }

    textarea {
        height: 96px;
        margin-top: 10px;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 5%;
        padding-top: 50px;

        input, select {
            border-radius: 0;
            border: none;
            border-bottom: 1px solid black;
            padding: 10px 5px;

            ::placeholder {
                font-size: 20px;
                color: $LightGrey;
            }
        }

        button {
            padding: 10px 20px;
            border: none;
            border-radius: 0;
            background-color: $Teal;
            color: #fff;
            margin: auto;
            display: block;
            width: 100%;
            max-width: 300px;
            font-size: 18px;
            cursor: pointer;
            text-transform: uppercase;
            font-family: 'Hero New', sans-serif;
            font-weight:600;
        }

        input[type=radio] {
            width: auto;
        }
    }

    .col-md-6 {
        width: 100%;

        .input-div {
            max-width: 100%;
            margin: 0 10px;
            position: relative;

            .label-wrapper {
                position: relative;
                background-color: #fff;
                padding: 0 5px;
                z-index: 2;
                font-size: 20px;
                font-weight: 400;
            }

            textarea {
                resize: none;
            }

            .radio-wrapper {
                border: none;
                min-height: 38px;
                margin: 10px 5px;
                text-align: left;
                padding-right: 10px;

                input {
                }

                .mr-10 {
                    margin-right: 10px;
                    color: $LightGrey;
                }
            }
        }
    }

    @media(min-width:$break-medium) {
        .col-md-6 {
            flex: 45%;

            .input-div {
                max-width: 100%;
                margin: 0 10px;
                position: relative;
                /*                .label-wrapper {
                    position: relative;
                    background-color: #fff;
                    left: 10px;
                    padding: 0 5px;
                    z-index: 2;
                }*/

                .radio-wrapper {
                    border: none;
                    min-height: 38px;
                    padding-right: 10px;
                    align-items:center;
                    input{margin-right:10px;}

                }
            }
        }
    }
}

.creditUnion-footer {
    padding: 20px;

    .creditUnion-footer-container {
        display: block;
        margin: auto;
        max-width: 1000px;
        width: 100%;

        div {
            width: 100%;
        }

        .footer-logo {
            max-width: 250px;
            margin: 20px auto;
            display: flex;

            img {
                object-fit: contain;
            }
        }

        .footer-content {
            border-left: 2px solid #fff;
            padding-bottom:100px;
        }
    }
}

.referral-page.footer-copyright {
    padding-top: 100px;
    background: #efefef;
    padding: 30px 30px 30px 30px;

    .footer-privacy {
        width: 100%;
        text-align: center;
        max-width: 100%;
        margin: auto;
        padding-bottom: 50px;

        a {
            color: #000;
            margin-bottom: 20px;
            display: block;
        }
    }

    @media(min-width:1000px) {
        max-width: none;
        padding-top:100px;
        .footer-privacy {
            width: 100%;
            text-align: right;
            max-width: 100%;
            margin: auto;
            padding-bottom: 50px;

            a {
                color: #000;
                margin-bottom: 20px;
                display: block;
            }
        }
    }
}
.creditUnion-WhiteStripe {
    height:5px;
    background-color:#fff;
}
.creditUnion-RedStripe {
    height: 5px;
    margin-top:40px; 
    background-color: #b21e28;
}


#fileUploaded {
    position: relative;
    left: 30px;
    top: 15px;
    font-size: 1rem;

}

.filebutton {
    margin-left: auto;
    margin-right: auto;
}

.modal.in .modal-dialog {
    top: 40vh;
    z-index: 9999;
    border-radius: 0;

    .modal-title {
        color: $Teal;
    }

    .filebutton {
        //  margin-right: 15px;
    }
}

#fileuploadWrapper {
    background-color: #2e31340a;
    padding: 40px 50px;
    margin-bottom: 30px;
    border: 2px dashed #bfbebe;
    border-radius: 3px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    color: #7f858a;

    #warning {
        font-size: 1.2rem;
        line-height: 1;
        text-transform: none;
    }
}

#fileListModal {
    display: block;
    margin: 15px auto;
    // border-top: 1px inset $gray-lighter;
    // border-bottom: 1px inset $gray-lighter;
    padding: 10px 0;

    li {
        p {
            margin-left: 20px;
            margin-bottom: 0;
        }

        span {
            margin-left: 20px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.fileUploader {
    display: none;
}

.upload-wrapper {
    clear: both;
}

.optin-wrapper {
    padding-left:10px;
}