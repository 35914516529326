﻿@import "_mediaqueries.scss";
@import "_color-variables.scss";

.image-w-callout {
    position: relative;

    .callout-section {
        position: relative;
        padding: 30px;

        .left-arrow, .right-arrow {
            position: absolute;
            display: none;
        }
    }

    .top-text {
        padding: 20px;
    }

    @media(min-width:1000px) {
        .top-text {
            position: absolute;
            top: 20px;
            width: 80%;
            right: 10%;
        }

        .callout-section {
            position: absolute;
            width: 66%;
            right: 0;
            bottom: 0;
            padding: 20px;
            display: flex;
            justify-content: flex-end;
            overflow: hidden;

            &.Left-callout {
                right: auto;
                left: 0;
                justify-content: left;
            }
        }

        img {
            margin-bottom: 80px;
        }
    }

    @media (min-width:1400px) {
        img {
            margin-bottom: 80px;
        }

        .top-text {
            position: absolute;
            top: 10%;
            right: 10%;
            width: 50%;
        }

        .callout-section {
            padding: 100px;


            .left-arrow, .right-arrow {
                position: absolute;
                display: block;
            }

            .left-arrow {
                top: 0;
                height: 100%;
                bottom: 0;
                right: 84%;
            }

            .right-arrow {
                top: 0;
                height: 100%;
                bottom: 0;
                left: 16%;
            }

            &.Left-callout {
                .left-arrow {
                    right: 16%;
                }

                .right-arrow {
                    left: 84%;
                }
            }

            .call-text {
                width: 70%;
                position: relative;
                right: 0;
            }
        }
    }
}
