﻿@import "_mediaqueries.scss";
@import "_color-variables.scss";

.contact-page-container {
    background-image: url('../../../images/bricks-1.png');
    background-size: cover;
    position: relative;
    top: 0;
    bottom: 0;

    .contact-container {
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        position: relative;
    }



    .contact-left {
        width: 100%;
        max-width: 100%;
        display: none;

        .contact-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 50px;
        }
    }

    @media(min-width:769px) {
        .contact-left {
            max-width: 100%;
            display: block;
        }
    }


    .contact-right {
        width: 100%;

        .contact-form {
            padding: 50px;
            background-color: #fff;
            box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.20);

            .contact-form-header {
                margin-bottom: 50px;
                margin-top: 50px;
            }

            label {
                font-size: 1.6rem;
                font-weight: 400;
            }

            input {
                font-size: 1.4rem;
            }

            .check-label-col {
                font-size: 1.2rem;
            }

            .btn {
                background-color: $Teal;
                height: auto;
                width: auto;
                text-align: center;
                color: #fff;
                justify-content: center;
                text-transform: uppercase;
                display: block;
                padding: 10px 40px;
                font-size: 1.8rem;
                margin-top: 20px;
                margin-bottom: 20px;
                border: 2px solid $Teal;
                cursor: pointer;
                font-weight: 600;

                &:hover {
                    background: none;
                    border: 2px solid $Teal;
                    color: $Teal;
                }
            }

            .radio-wrapper {
                display: flex;
                flex-direction: row;

                .radio-group {
                    width: 26%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    &:first-child {
                        width: 48%;
                    }

                    label {
                        width: 80%;
                        font-size: 1.2rem;
                    }

                    input {
                        width: auto;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .green-stripe {
        background-color: $Teal;
        padding-top: 50px;
        padding-bottom: 100px;
    }
}

@media(min-width:769px){
    .contact-left{
        max-width:100%;
    }
}

@media(min-width:1090px) {
    .contact-page-container {
        padding: 10px 50px;

        .green-stripe {
            margin-top: 150px;
            margin-bottom: 250px;
        }

        .contact-left {
            width: 50%;
        }

        .contact-right {
            width: 50%;
            position: absolute;
            right: 0;
            top: -100px;
            bottom: -150px;
        }

        .contact-form {
            margin-right: 100px;
        }
    }
}

.application-form {
    .btn {
        background-color: $Teal;
        height: auto;
        width: auto;
        text-align: center;
        color: #fff;
        justify-content: center;
        text-transform: uppercase;
        display: block;
        padding: 10px 40px;
        font-size: 1.8rem;
        margin-top: 20px;
        margin-bottom: 20px;
        border: 2px solid $Teal;
        cursor: pointer;
        &:hover{
            background:none;
            border:2px solid $Teal;
            color:$Teal;
        }
    }
}

.section {
    .contact-form {
        input {
            padding: 0 20px 5px 20px; 
            padding-left: 0;
            color:#979797;
        }

        label {
            font-size: 1.6rem;
            font-weight: 400;
            color: black;
        }

        .check-label-col {
            font-weight: 400;
        }
    }
}
.contact-form {

    .btn {
        background-color: $Teal;
        height: auto;
        width: auto;
        text-align: center;
        color: #fff;
        justify-content: center;
        text-transform: uppercase;
        display: block;
        padding: 10px 40px;
        font-size: 1.8rem;
        margin-top: 20px;
        margin-bottom: 20px;
        border: 2px solid $Teal;
        cursor: pointer;
        font-weight:600;
        &:hover {
            background: none;
            border: 2px solid $Teal;
            color: $Teal;
        }
    }

    input {
        padding-left: 0;
    }

    label {
        font-size: 1.6rem;
        font-weight: 400;
    }

    .check-label-col {
        font-weight: 400;
        font-size: 1.2rem;
    }
}
.contact-form .radio-wrapper {
    display: flex;
    flex-direction: row;
}
.contact-form .radio-wrapper .radio-group:first-child {
    width: 48%;
}
.contact-form .radio-wrapper .radio-group {
    width: 26%;
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
        color: #979797;

        &.error {
            color: #b21e28;
        }
    }
}
.contact-form .radio-wrapper .radio-group input {
    width: auto;
    margin-right: 10px;
}

.section form .column.what-brought{
    display:none;
}

.contact-page-container {
    .section form .column.what-brought {
        display: block;
    }
}

.section form.contact-form {

    label.error {
        color: #b21e28;
    }
}