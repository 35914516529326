﻿@import "_mediaqueries.scss";
@import "_color-variables.scss";

.social-bar {
    background-color: $Light-Gray;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;

    .social-item {
        display: inline-block;
        padding: 0px 20px;
    }

    .social-icon {
        text-align: center;

        .fa-twitter-square, .fa-facebook-square, .fa-linkedin {
            font-size: 5rem;
        }
    }
}

.new-footer {
    width: 100%;

    .navbar-item, .navbar-link, .privacy {
        font-weight: 600;
        font-family: 'Allotrope',sans-serif;
        font-size: 12px;
        margin-top: 0;
    }

    @media(max-width:$break-medium) {


        .section .column.is-2-desktop {
            padding: 20px 30px;
        }
    }

    @media(min-width:$break-medium) {
        &.microsite-footer-new {
            .footer-left-col {
                width: 60%;
            }
        }
    }

    .copyright {
        color: #979797;
        font-weight: 300;
        font-size: 10px;

        p {
            display: inline;
            color: #979797;
            font-weight: 300;
            font-size: 10px;
        }
    }
}

.social-footer{
    .columns{
        display:flex;
        .nav-preview-header{ flex-grow:unset;min-width:135px;}
        .social-item{padding-top:7px;}
    }
}

.footer-nav{
    .nav-footer-header{ margin-bottom:0; }
   .subnav-item{ margin-bottom:10px; }

}