﻿$Dark: #363636;
$White: #fff;
$Light: #f5f5f5;
$Gray: #e8e8e8;
$LightGrey: #979797;
$LightBlue: #008bce; 
$Red: #b21e28;
$Blue: #006db6;
$DarkBlue: #051c2c;
$Light-Gray: #979797;
$Yellow:#ffcc00;
$Green:#80c904;
$Teal: #3c9c89;
$DarkTeal: #04aaa2;
$DarkGreyBlue: #333f47;
$OffWhite: #efefef;

$link: $Blue;
$info: $Light-Gray;
$success: $Green;
$warning: $Yellow;
$danger: $Red;

.light-grey-text{color:$LightGrey;}
.light-blue-text{color:$LightBlue;}
.white-txt{color:#fff;}
.cyan-text, .cyan-text a {color:$LightBlue; }

.teal-text{ color:$Teal;}
.dk-blue-text {color:$DarkBlue;}
.dk-grey-blue-text {color:$DarkGreyBlue;}

.dark-blue-background { background-color:$DarkBlue; }
.light-blue-background { background-color:$LightBlue; }
.light-grey-background { background-color:$LightGrey; }
.off-white-background { background-color:$OffWhite; }
.dark-grey-background {background-color:$DarkGreyBlue; color:#fff;}

.content{
h1,h2,h3,h4,h5,h6{
    color:unset;
}

}