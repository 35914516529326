﻿@import "_mediaqueries.scss";
@import "_color-variables.scss";
@import url("https://use.typekit.net/iyi0xyr.css");


.navbar {
    background-color: #fff;

    .container {
        .navbar-brand.microsite {
            .navbar-logo {
                height: 150px;
                overflow: hidden;
                position: fixed;
                left: 0;
                display: block;
                width: 330px;
                position: relative;
                width: 80%;

                .logo {
                    margin: 0 0 10px 0;
                    height: 100px;
                    width: 85%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: left center;
                    position: relative;
                }

                @media (min-width:$break-small) {
                    padding: 15px;

                    .logo {
                        width: 300px;
                        background-size: 100%;
                    }
                }

                @media (min-width:$break-medium) and (max-width:$break-large) {
                    width: auto;
                    margin-right: 20px;

                    .logo {
                        margin: 0;
                    }
                }

                @media (min-width:1060px) {
                    height: 150px;
                    overflow: hidden;
                    left: 15px;
                    display: block;
                    width: 330px;
                    position: relative;
                }
            }
        }

        #header {
            &.small {
                @media(min-width:$break-small) {
                    .navbar-logo {
                        height: 100px;
                        width: 90px;
                        margin-right: 20px;

                        .logo {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}





.microsite-banner {
    .subhead {
        padding: 20px 0;
    }

    &.image {
        img.microsite-image {
        object-fit: contain;
        height:unset;
        width:unset;
        position: relative;
        max-width:300px;
    }
    }

    @media (min-width:$break-medium) {    }

    @media (min-width:$break-large)  {    }
}


.microsite-body {
    .navbar .container .navbar-brand .navbar-logo {
        height: 80px;
    }


    .header-fixed-top {
        background-color: #fff;

        .navbar {
            background-color: #fff;
        }
    }


    :focus {
        outline: none;
    }

    img.small-logo {
        height: 50px;
    }

    .navbar.active {
        img.small-logo {
            opacity: 0;
        }
    }

    .navbar-toggle {
        color: $DarkGreyBlue;
    }

    .news-ticker {
        height: 86px;
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        color: #fff;
    }

    .sidebar {
        form {
            padding-top: 20px;
        }
    }

    .slider-item {
        position: relative;
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
    }

    .carousel-item {
        margin-top: 20px;
        margin-bottom: 20px;
        -webkit-box-shadow: 1px 1px 10px 0px rgba(102,102,102,1);
        -moz-box-shadow: 1px 1px 10px 0px rgba(102,102,102,1);
        box-shadow: 1px 1px 10px 0px rgba(102,102,102,1);
        border-radius: 25px;
        overflow: hidden;
        border: 1px solid #fff;
        background: #fff;
        z-index: 100;
        flex-grow: 1;
        /*&.center-slide{
            height:370px;
            margin-bottom:20px;
            width:300px;
            
            position:absolute;
            z-index:1000;
        }*/
    }

    .carousel-title {
        padding: 5px 20px 0 20px;
    }

    .carousel-subtitle {
        padding: 10px 20px;
    }

    .product-slide {
        width: 110%;
        position: relative;
        top: -20px;
        left: -5%;
        transform: rotate(-6deg);
        margin: auto;
        height: 225px;
        overflow: hidden;

        img {
            transform: rotate(6deg);
            position: absolute;
            bottom: -20px;
        }
    }



    .navbar-logo-container {
        height: 115px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 100;

        &.small {
            background-color: unset;
            height: auto;

            a {
                align-items: center;
                justify-content: center;
                vertical-align: middle;
                display: flex;
                display: -webkit-flex;
            }
        }

        .logo {
            position: relative;
            height: 25px;
        }
    }

    .navbar {
        .reverse-logo {
            margin-left: 0;
        }

        .navbar-item {
            padding-right: 15px;
        }
    }

    .nav-background {
        @media (min-width:$break-medium) {
            top: 192px;
        }
    }
    /*.navbar {
        background-color: #f6f6f6;

        .navbar-item {
            padding: 17px;

            &.cta-button-blue {
                width: auto;
                padding: 6px 17px;

                a {
                    color: #fff;
                }

                &:hover {
                    a {
                        color: $Blue;
                    }
                }
            }

            a {
                color: #4d4d4d;
            }
        }
    }*/
    .contact-page-container .contact-left {
        display: block;
    }

    .microsite-footer {
        font-size: 1.2rem;
        padding-bottom: 60px;
        position: relative;
        padding-top: 60px;
        color: #fff;



        .footer-nav {
            border-bottom: 2px solid $Gray;
        }

        .footer-legal {
            margin: 16px 20px;
        }

        .microsite-link {
            font-size: 1.4rem;
            font-weight: bold;
            color: $White;
            font-style: italic;
            margin-top: -14px;
        }

        .column {
            padding: 1rem 2rem;
        }
    }

    .triangle {
        background-color: #e6e6e6;
        color: white;
        margin-bottom: 80px;
        position: relative;
        text-align: center;
        text-decoration: none;
    }

    .triangle:after {
        background-color: inherit;
        clip-path: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg"%3E%3Cdefs%3E%3CclipPath id="p" clipPathUnits="objectBoundingBox"%3E%3Cpolygon points="0 0, 1 0, 0.5 1" /%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E#p'); /* fix for firefox (tested in version 52) */
        clip-path: polygon(0 100%,100% 0,100% 100%);
        content: '';
        height: 81px;
        left: 0;
        position: absolute;
        right: 0;
        top: 100%;
    }

    .header-fixed-top {

        .navbar {
            height: auto;

            .navbar-end {
                a {
                    color: $DarkBlue;
                }

                .current-page, .navbar-link.current-page {
                    border: none;
                    border-radius: 0;
                    margin: unset;
                    box-shadow: none;

                    @media(min-width:$break-medium) {
                        &:before {
                            content: "\0000a0";
                            border-bottom: 2px $Teal solid;
                            width: 40px;
                            position: absolute;
                            left: 20px;
                            bottom: 0px;
                        }
                    }
                }
            }
        }

        .navbar .container .navbar-brand .navbar-logo img {
            max-height: 100%;
            max-width: 100%;
            height: auto;
            image-rendering: -webkit-optimize-contrast;
        }

        @media (min-width:$break-large) {
            position: fixed;
            width: 100%;
            z-index: 1000;

            .navbar {
                .navbar-end {
                    width: 100%;
                    justify-content: flex-end;

                    a {
                        color: $DarkBlue;
                    }

                    .current-page, .navbar-link.current-page {
                        border: none;
                        border-radius: 0;
                        margin: unset;
                        box-shadow: none;

                        &:before {
                            content: "\0000a0";
                            border-bottom: 2px $Teal solid;
                            width: 40px;
                            position: absolute;
                            left: 0;
                            bottom: -5px;
                        }
                    }
                }
            }
        }
    }

    main {
        padding-top: 130px;
    }

    .navbar.has-shadow.is-spaced {
        box-shadow: none;
        /**/
    }

    .columns.single-col {
        display: block;
    }

    .RTE {
        padding: 20px 0;
    }



    .hero-banner {
        @media(min-width:$break-medium) {
            &.microsite-banner {
                min-height: 500px;
            }
        }

        &.side-flag-banner {
            .column {
                position: relative;
                padding: 0;
            }

            .side-flag-textContent {
                padding: 100px 30px;
                box-sizing: border-box;
            }

            .image {
                img.is-background {
                    margin: 0;
                }
            }

            .title-wrapper {
                display: flex;
                display: -webkit-flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }

            .title-flag {
                background-color: #fff;
                padding: 30px;
                z-index: 1;
                color: $Blue;
                text-transform: uppercase;
                width: 100%;
                font-family: proxima-soft, sans-serif;
                font-style: normal;
                font-weight: 600;
                letter-spacing: 0;
                box-shadow: 6px 6px 6px #00000029;

                @media (min-width:$break-medium) {
                    position: absolute;

                    &.left-flag {
                        right: 0;
                    }

                    &.right-flag {
                        left: 0;
                        padding-left: 100px;
                        border-top-left-radius: 60px;
                        border-bottom-left-radius: 60px;
                    }
                }
            }

            @media(min-width:$break-medium) {
                .right-shadow {
                    -webkit-box-shadow: 30px 0 20px -30px rgba(51,51,51,.5);
                    -moz-box-shadow: 30px 0 20px -30px rgba(51,51,51,.5);
                    box-shadow: 30px 0 20px -30px rgba(51,51,51,.5);
                }

                .left-shadow {
                    -webkit-box-shadow: -30px 0 20px -30px rgba(51,51,51,.5);
                    -moz-box-shadow: -30px 0 20px -30px rgba(51,51,51,.5);
                    box-shadow: -30px 0 20px -30px rgba(51,51,51,.5);
                }
            }
        }


        .hero-body {
            /*padding-bottom: 6rem;
            padding-top: 6rem;*/
            padding-left: 0;
            padding-right: 0;

            .block {
                &.text-align-center {
                    text-align: center;
                }

                .title {
                    &.line-below {
                        border-bottom: 2px solid #006db6;
                        padding-bottom: 20px;
                    }

                    &.line-above {
                        border-top: 2px solid #006db6;
                        padding-top: 20px;
                    }
                }
            }


            .icon-section {
                max-width: 1000px;
                margin: 0 auto;
            }

            .icon-item {
                .icon-image {
                    &.fill-space {
                        height: 300px;
                        overflow: hidden;

                        img {
                            object-fit: cover;
                            object-position: top;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }

                img {
                    position: relative;
                    margin: 0 auto;
                    width: auto;
                }

                .title {
                    display: block;
                    border-top: none;
                    padding: 20px 0 0 0;
                    color: #333333;
                }

                .blue-text {
                    .title {
                        color: $Blue;
                    }
                }

                .red-text {
                    .title {
                        color: $Red;
                    }
                }

                .light-gray-text {
                    .title {
                        color: $Light;
                    }
                }

                .dark-text {
                    .title {
                        color: $Dark;
                    }
                }

                .white-text {
                    .title {
                        color: $White;
                    }
                }

                .gray-text {
                    .title {
                        color: $Gray;
                    }
                }

                .subtitle {
                    p {
                        margin-top: 10px;
                    }
                }
            }
        }

        &.image, .image {
            img.is-background {
                object-fit: cover;
                object-position: top center;
                margin: 0;
                bottom: 0;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;

                @media(min-width:$break-large) {
                    object-fit: cover;
                    width: 100%;
                }
            }

            &.contain-bg {
                img.is-background {
                    object-fit: contain;

                    @media(min-width:$break-large) {
                        object-fit: contain;
                    }
                }
            }

            &.top-right-background {
                img.is-background {
                    object-position: top right;
                }
            }

            &.top-left-background {
                img.is-background {
                    object-position: top left;
                }
            }

            &.left-shadow {
                img.is-background {
                    left: 30px;
                }
            }

            &.right-shadow {
                img.is-background {
                    right: 30px;
                }
            }
        }




        .color-stripe {
            width: 100%;
            background-color: #006db6;
            color: #fff;

            .title {
                color: #fff;
                /*text-transform: uppercase;*/
                padding: 20px 60px 20px 60px;
            }

            &.block {
                margin-bottom: 1.5rem;
            }
        }


        &.microsite-banner {
            .button-box {

                .buttons {
                    justify-content: unset;
                }

                .button {
                    height: auto;

                    &.cta-button-blue {
                        border: 2px solid $Blue;
                        width: auto;
                    }
                }
            }
        }
    }

    @media (max-width: 950px) {
        .navbar .navbar-menu {
            top: 130px;
        }

        .navbar.active .navbar-menu {
            left: 0;
            right: 0;
            transition: all 1s ease;
            height: 100%;
            background-color: #fff;
            overflow-y: scroll;
            top: 130px;
        }

        .navbar .navbar-item {
            color: #000;
            padding: 10px 30px;
            display: block;
            font-size: 2rem;
            cursor: pointer;
            z-index: 100;
            position: relative;
            box-sizing: border-box;
            margin: 1px;
            min-height: 0;
            text-align: center;
        }

        .navbar .navbar-end {
            min-height: 800px;
        }

        .navbar.active .container .navbar-brand .navbar-logo {
            display: none;
        }
        /*        .navbar.active .navbar-link{
            text-align:left;
        }*/
    }
}


.microsite-footer-new {
    &.new-footer .footer-left-col {
        display: block;
        width:100%;
        .navbar-item {
            color: #000;

            .navbar-link {
                color: #000;
                font-size:18px;
                padding-bottom:10px;
                text-transform:uppercase;
            }
        }
    }

    &.new-footer .privacy{
        text-align:left;
        font-size:18px;
    }

    @media (min-width:$break-medium) {
        &.new-footer{
            display:flex;
        }
        &.new-footer .footer-left-col {
            display: flex;

            .navbar-item {
                color: #000;

                .navbar-link {
                    color: #000;
                    font-size:12px;
                    padding-bottom:0;
                }
            }
        }
        &.new-footer .privacy {
            text-align:right;
            font-size:12px;
        }

    }


    @media (min-width: 1200px) {
        &.new-footer .footer-right-col {
            padding-left: 20px;
            border-left: none;
            /* border-left: 1px solid; */
        }
    }

    @media (min-width: 1400px) {
        &.new-footer .footer-right-col {
            width: 50%;
        }
    }
}
.microsite-copyright {
    max-width: 1400px;
    margin: auto;

    padding-left: 30px;
    padding-right: 30px;

    @media(min-width:$break-medium){
        padding-left:0;
        padding-right:0;
    }


    footer {
        width: 100%;
        margin-right: 0;
        margin-left: auto;
        padding-right: 0;
        padding-left: 0;
        padding-top: 0;

        .has-text-centered {
            text-align: left !important;
        }

        @media(min-width:$break-medium) {
            .has-text-centered {
                text-align: center !important;

            }
            }
        }

        .copyright {
            p {
                margin-left: 10px;
                display: inline;
            }

            text-align: left;
            justify-content: left;

            @media(min-width:$break-medium) {
                display: -webkit-flex;
                display: flex;
                justify-content: right;
                -webkit-justify-content: flex-end;
            }

            color: $LightGrey;
        }
    }

    .microsite-master {
        .breadcrumb {
            display: none;
        }
    }

.section .microsite-content {

    .contact-form-header {
        margin-bottom: 50px;
    }

    .contact-form {
        background: #fff;
        padding: 20px;
        margin-bottom: 150px;
        max-width: 80%;

        input {
            padding: 5px 0;
        }



        @media(min-width:600px) {
            padding: 50px 100px;
        }


    }



    form.contact-form {
        padding: 0;
        max-width: 100%;
        margin-bottom: 0;

        label {
            font-weight: 400;
        }

        .check-label-col {
            font-weight: 400;
            font-size: 12px;
        }
    }

    .contact-page-container {


        .blue-stripe {
            background-color: $DarkBlue;
            padding-top: 20px;
            padding-bottom: 20px;
            position: relative;
            width: 100%;

            .blue-cta {
                color: $LightBlue;
                position: relative;
                left: 0;
                width: 100%;
                padding: 20px;
                font-family: 'Allotrope', sans-serif;
                font-size: 4.2rem;
            }

            @media(min-width:$break-medium) {
                .blue-cta {
                    font-size: 4.6rem;
                }
            }
        }

        @media(min-width:1000px) {
            .contact-left {
                width: 65%;
            }

            .blue-stripe {
                background-color: $DarkBlue;
                padding-top: 50px;
                padding-bottom: 50px;
                position: absolute;
                width: 100%;
                bottom: 50px;

                .blue-cta {
                    color: $LightBlue;
                    position: relative;
                    left: 70%;
                    width: 25%;
                    font-family: 'Allotrope', sans-serif;
                    font-size: 45px;
                }
            }
        }
    }

    @media (min-width: 1090px) {
        .contact-page-container {
            padding: 10px 0;

            .contact-form {
                margin-right: auto;
            }
        }
    }
}



input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    opacity: 0;
}

input::-moz-placeholder { /* Firefox 19+ */
    opacity: 0;
}

input:-ms-input-placeholder { /* IE 10+ */
    opacity: 0;
}

input:-moz-placeholder { /* Firefox 18- */
    opacity: 0;
}

@media(min-width:$break-medium) {
    input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        opacity: 1;
    }

    input::-moz-placeholder { /* Firefox 19+ */
        opacity: 1;
    }

    input:-ms-input-placeholder { /* IE 10+ */
        opacity: 1;
    }

    input:-moz-placeholder { /* Firefox 18- */
        opacity: 1;
    }
}