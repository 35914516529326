﻿@import "_mediaqueries.scss";
@import "_color-variables.scss";

.header-btn {
    font-size: 24px;
    background-color: $Teal;
    height: 60px;
    width: 200px;
    display: flex;
    border-radius: 0;
    text-align: center;
    color: #fff;
    justify-content: center;
    flex-direction: column;
    text-transform: uppercase;
    margin: 30px 20px;
    border:2px solid $Teal;

    &:hover {
        text-decoration: none;
        background:none;
        border:2px solid $Teal;
        color:$Teal;
    }

    @media(min-width:1400px) {
        margin: 30px 0;
    }
}

.buttons {
    padding-left:30px;
    a {
        background-color: $Teal;
        height: auto;
        width: auto;
        text-align: center;
        color: #fff;
        justify-content: center;
        text-transform: uppercase;
        display: block;
        padding: 10px 40px;
        font-size: 1.8rem;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        border: 2px solid $Teal;
        font-weight:600;

        &:hover {
            text-decoration: none;
            background: none;
            border: 2px solid $Teal;
            color: $Teal;
        }

        @media(min-width:1400px) {
            margin-left: 0;
            margin-right: 0;
        }
    }
    @media(min-width:$break-medium){
        padding-left:0;
    }
}

.button-section.align-center{
    .buttons{ padding-left:0; }
}
.color-highlight-section{
    .buttons{ padding-left:0;}
}

.icon-item {
    .buttons {
        padding-left: 0;
    }

}

@media(min-width:$break-medium){
    .color-highlight-section {
        .buttons {
            padding-left: 30px;
        }
    }
}


.info-btn{
    &:hover{text-decoration:none;}
}

