﻿@import "_mediaqueries.scss";
@import "_color-variables.scss";

.header-content-blocks {
    position: relative;
    top: 0;

    .header-content-block{
        margin-bottom:20px;
    }

    @media(min-width:$break-small) {
        .flex-content {
            min-height: 350px;
            display: block;
            margin: 0 -5px;
            max-width: 1600px;
            margin: auto;
            flex: 1;
            gap: 2%;
            flex-grow: 1;
        }

        .header-content-block {
            margin-bottom: 10px;
        }
    }

    @media(min-width:$break-medium) {
        .flex-content {
            min-height: 350px;
            display: flex;
            margin: 0 -5px;
            max-width: 1400px;
            margin: auto;
            flex: 1;
            gap: 2%;
            flex-grow: 1;
        }

        .header-content-block {
            margin-bottom: 0;

            &.col-xs-4 {
                display: inline-block;
                margin: 0;
                flex: 1;
            }
        }
    }

    @media(min-width:$break-large) {
        top: 525px;
        position: absolute;
    }

    margin: auto;
    width: 100%;



    .header-content-block {



        .header-title-box {
            background-color: rgba(5,110,182, .8);
            height: 75px;
            color: #fff;
            justify-content: center;
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        .header-info-box {
            text-align: center;
            background-color: $DarkBlue;
            justify-content: center;
            display: flex;
            flex-direction: column;
            text-align: center;
            height: 100%;
            font-size: 20px;
            padding: 10px;
            color: #fff;

            @media(min-width:$break-large) {
            }

            .info-btn {
                font-size: 18px;
                border-radius: 0;
                border: 2px $Teal solid;
                width: 200px;
                margin: 20px auto;
                padding: 5px 20px;
                color: $Teal;

                &:hover {
                    color: #fff;
                    background-color: $Teal;
                }
            }
        }

        .header-stripe {
            background: rgb(4,170,162);
            background: linear-gradient(90deg, rgba(4,170,162,0.8) 0%, rgba(126,255,248,0.8) 15%, rgba(54,187,180,0.8) 30%, rgba(117,247,240,0.8) 45%, rgba(54,187,180,0.8) 55%, rgba(123,232,225,0.8) 70%, rgba(145,246,240,0.8) 85%, rgba(54,187,180,0.8) 100%);
            height: 20px;
        }
    }
}


.home-body{
    .hero-banner-content{
/*        max-width:1400px;*/
        width:100%;
        margin:0 auto;
    }
}