﻿
@import "_mediaqueries.scss";
@import "_color-variables.scss";

body, button, input, select, textarea {
    font-family: hero-new, sans-serif;
}

.oswald{
    font-family:'Oswald', sans-serif;
}
.oswald-light {
    font-family: 'Oswald', sans-serif;
    font-weight:300;
}
.roboto {
    font-family:'Roboto', sans-serif;
}
.hero-new {
    font-family: hero-new, sans-serif;
}
.alltrope {
    font-family: allotrope, sans-serif;
}

a{color:$Blue;}
.is-bold {font-weight:700;}
button, img, a {
    &:active, &:focus{
        box-shadow:none;
        outline:none;
        outline-color:unset;

    }
}

img {
    image-rendering: -webkit-optimize-contrast;
}

.left-text {
    text-align:left;
}
.right-text {
    text-align:right;
}
.center-text {
    text-align:center;
}

.title{
    color:unset;
}

main {
    background: #fff;
    padding-top:100px;

}
html {
    font-size: 62.5%;
}
body {
    font-size:1.4rem;
}

.font-size-1, .font-size-1 .title, h3.font-size-1.title {
    font-size:1.4rem; line-height:1.5em;
}
.font-size-2, .font-size-2 .title, h3.font-size-2.title {
    font-size: 1.6rem;
    line-height: 1.5em;
}
.font-size-3, .font-size-3 .title, h3.font-size-3.title {
    font-size: 1.8rem;
    line-height: 1.5em;
}
.font-size-4, .font-size-4 .title, h3.font-size-4.title {
    font-size: 2.4rem;
    line-height: 1.5em;
}
.font-size-5, .font-size-5 .title, h3.font-size-5.title {
    font-size: 3rem;
    line-height: 1.5em;
}
.font-size-6, .font-size-6 .title, h3.font-size-6.title {
    font-size: 3rem;
    line-height: 4rem;
    @media(min-width:$break-medium) {
        font-size: 3.6rem;
        line-height:4.5rem;
    }
}
.font-size-7, .font-size-7 .title, h3.font-size-7.title {
    font-size: 3rem;
    line-height: 3.5rem;
    @media(min-width:$break-medium) {
        font-size: 4.2rem;
        line-height:5rem;
    }
}
.font-size-8, .font-size-8 .title, h3.font-size-8.title {
    font-size: 4rem;
    line-height: 4rem;
    @media(min-width:$break-medium) {
        font-size: 6rem;
        line-height:6.5rem;
    }
}
.font80, .title.font80 {
    font-size: 48px;
    line-height:50px;
    @media(min-width:$break-small) {
        font-size: 60px;
        line-height:60px;
    }

    @media(min-width:$break-medium) {
        font-size: 80px;
        line-height: 80px;
    }
}
.font120, .title.font120 {
    font-size: 60px;

    @media(min-width:$break-small) {
        font-size: 80px;
        line-height:80px;
    }

    @media(min-width:$break-medium) {
        font-size: 120px;
        line-height:110px;
    }
}
.narrow-text
{
    max-width:700px;
    display:block;
}
.clearfix:after, .clearfix:before { display:table; content:""; clear:both;}
.column{
    justify-content:center;
}
.columns.switch-order {
    flex-direction:row-reverse;
}

.input {border-radius: 0;}

.five-col{
    width:100%;
    @media(min-width:$break-medium){
    width:20%;}
}
.cta-button, .navbar-item.cta-button {
    color: #000;
    font-size: 1.4rem;
    font-weight: 700;
    border: 2px solid #000;
    border-radius: 20px;
    max-width: 300px;
    width: auto;
    cursor: pointer;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    -webkit-box-shadow: -1px 1px 10px 0px rgba(214,214,214,1);
    -moz-box-shadow: -1px 1px 10px 0px rgba(214,214,214,1);
    box-shadow: -1px 1px 10px 0px rgba(214,214,214,1);
    padding: 6px 20px;
    margin: 6px auto;
    font-family: 'Oswald', sans-serif;

    a, .navbar-link:not(.is-arrowless) {
        margin-right: auto;
    }

    &:hover {
        margin: 3px auto 9px auto;
    }
}
.cta-button-red {
    color: #fff;
    font-size: 1.4rem;
    font-weight: 600;
    background-color: #b21e28;
    display: block;
    padding: 6px 12px;
    margin: 6px auto;
    max-width: 300px;
    width: 100%;
    cursor: pointer;
    border: none;
    border-radius: 0;
    text-transform: uppercase;

    &:hover {
        color: #efefef;
        margin: 3px auto 9px auto;
    }
}
.cta-button-blue, .cta-button-blue.buttons:last-child {
    padding: 6px 17px;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 50px;
    background-color: transparent;
    border: 2px solid black;
    border-radius: 50px;
    margin: 6px auto;
    max-width: 300px;
    width: auto;
    cursor: pointer;
    text-transform: uppercase;
    color: #000;
    display: inline-block;
    text-align: center;
    -webkit-box-shadow: -1px 1px 10px 0px rgba(214,214,214,1);
    -moz-box-shadow: -1px 1px 10px 0px rgba(214,214,214,1);
    box-shadow: -1px 1px 10px 0px rgba(214,214,214,1);
    font-family: 'Oswald', sans-serif;

    a, a.navbar-link, a.navbar-link.current-page {
        text-align: center;
        display: block;
        width: 100%;
        color: black;
        padding: .5rem .75rem;
        margin-left: auto;
        border-bottom: none;
    }

    &:hover {
        margin: 3px auto 9px auto;
        margin-bottom: 9px;
        border-color: $Blue;
    }

    &.sq-btn {
        border-radius: 5px;
        font-size: 18px;
        padding: 10px 0;
        max-width: 100%;
        text-transform: unset;
        width: 100%;
        margin-bottom: 0;
        margin-left:5px;
        margin-right:5px;
        &:hover {
            background-color:none;
            background:none;
            margin-bottom: 3px;
        }

        @media(min-width:1100px) {
            width: auto;
            padding: 10px 0;
        }

        @media(min-width:1100px) {
            padding: 30px;
        }
    }

    &:hover, &:active {
        background-color: #fff;
        color: $Blue;
        border: solid 2px $Blue;

        a, a.navbar-link, a.navbar-link.current-page {
            color: $Blue;
        }
    }
}
.image-overlay {
    display: none;
    &.overlay-w-25 {
        display: block;
        background-color: rgba(255, 255, 255, .25);
    }

    &.overlay-w-50 {
        display: block;
        background-color: rgba(255, 255, 255, .5);
    }

    &.overlay-w-75 {
        display: block;
        background-color: rgba(255, 255, 255, .75);
    }

    &.overlay-b-25 {
        display: block;
        background-color: rgba(0, 0, 0, .25);
    }

    &.overlay-b-50 {
        display: block;
        background-color: rgba(0, 0, 0, .5);
    }

    &.overlay-b-75 {
        display: block;
        background-color: rgba(0, 0, 0, .75);
    }
}


.section.has-background-grey-lighter .has-no-background .subtitle .blue-text,
.section.has-background-grey-lighter .has-no-background .title .blue-text,
.section.has-background-grey-lighter .has-no-background a:not(.button) .blue-text,
.section.has-background-grey-lighter .has-no-background li .blue-text,
.section.has-background-grey-lighter .has-no-background p .blue-text,
.section.has-background-grey-lighter .has-no-background span:not(.icon) .blue-text,
.section.has-background-grey-lighter .has-no-background strong .blue-text,
.section.has-background-grey-lighter .has-no-background svg .blue-text,
.section.has-background-grey-lighter .has-no-background ul .blue-text,
.section.has-background-light .has-no-background .subtitle .blue-text,
.section.has-background-light .has-no-background .title .blue-text,
.section.has-background-light .has-no-background a:not(.button) .blue-text,
.section.has-background-light .has-no-background li .blue-text,
.section.has-background-light .has-no-background p .blue-text,
.section.has-background-light .has-no-background span:not(.icon) .blue-text,
.section.has-background-light .has-no-background strong .blue-text,
.section.has-background-light .has-no-background svg .blue-text,
.section.has-background-light .has-no-background ul .blue-text,
.section.has-background-white .has-no-background .subtitle .blue-text,
.section.has-background-white .has-no-background .title .blue-text,
.section.has-background-white .has-no-background a:not(.button) .blue-text,
.section.has-background-white .has-no-background li .blue-text,
.section.has-background-white .has-no-background p .blue-text,
.section.has-background-white .has-no-background span:not(.icon) .blue-text,
.section.has-background-white .has-no-background strong .blue-text,
.section.has-background-white .has-no-background svg .blue-text,
.section.has-background-white .has-no-background ul .blue-text, .blue-text > .title {
    color: #006db6;
}

.blue-text {
    color: #006db6;
}

.section.has-background-grey-lighter .has-no-background .subtitle .teal-text,
.section.has-background-grey-lighter .has-no-background .title .teal-text,
.section.has-background-grey-lighter .has-no-background a:not(.button) .teal-text,
.section.has-background-grey-lighter .has-no-background li .teal-text,
.section.has-background-grey-lighter .has-no-background p .teal-text,
.section.has-background-grey-lighter .has-no-background span:not(.icon) .teal-text,
.section.has-background-grey-lighter .has-no-background strong .teal-text,
.section.has-background-grey-lighter .has-no-background svg .teal-text,
.section.has-background-grey-lighter .has-no-background ul .teal-text,
.section.has-background-light .has-no-background .subtitle .teal-text,
.section.has-background-light .has-no-background .title .teal-text,
.section.has-background-light .has-no-background a:not(.button) .teal-text,
.section.has-background-light .has-no-background li .teal-text,
.section.has-background-light .has-no-background p .teal-text,
.section.has-background-light .has-no-background span:not(.icon) .teal-text,
.section.has-background-light .has-no-background strong .teal-text,
.section.has-background-light .has-no-background svg .teal-text,
.section.has-background-light .has-no-background ul .teal-text,
.section.has-background-white .has-no-background .subtitle .teal-text,
.section.has-background-white .has-no-background .title .teal-text,
.section.has-background-white .has-no-background a:not(.button) .teal-text,
.section.has-background-white .has-no-background li .teal-text,
.section.has-background-white .has-no-background p .teal-text,
.section.has-background-white .has-no-background span:not(.icon) .teal-text,
.section.has-background-white .has-no-background strong .teal-text,
.section.has-background-white .has-no-background svg .teal-text,
.section.has-background-white .has-no-background ul .teal-text, .teal-text > h1, .teal-text > h2, .teal-text > h3 ,.teal-text > h4, .teal-text > .title {
    color: $Teal;
}
.teal-text{
    color:$Teal;
}

.teal-background{
    background-color:$Teal;
}

.section.has-background-grey-lighter .has-no-background .subtitle .red-text,
.section.has-background-grey-lighter .has-no-background .title .red-text,
.section.has-background-grey-lighter .has-no-background a:not(.button) .red-text,
.section.has-background-grey-lighter .has-no-background li .red-text,
.section.has-background-grey-lighter .has-no-background p .red-text,
.section.has-background-grey-lighter .has-no-background span:not(.icon) .red-text,
.section.has-background-grey-lighter .has-no-background strong .red-text,
.section.has-background-grey-lighter .has-no-background svg .red-text,
.section.has-background-grey-lighter .has-no-background ul .red-text,
.section.has-background-light .has-no-background .subtitle .red-text,
.section.has-background-light .has-no-background .title .red-text,
.section.has-background-light .has-no-background a:not(.button) .red-text,
.section.has-background-light .has-no-background li .red-text,
.section.has-background-light .has-no-background p .red-text,
.section.has-background-light .has-no-background span:not(.icon) .red-text,
.section.has-background-light .has-no-background strong .red-text,
.section.has-background-light .has-no-background svg .red-text,
.section.has-background-light .has-no-background ul .red-text,
.section.has-background-white .has-no-background .subtitle .red-text,
.section.has-background-white .has-no-background .title .red-text,
.section.has-background-white .has-no-background a:not(.button) .red-text,
.section.has-background-white .has-no-background li .red-text,
.section.has-background-white .has-no-background p .red-text,
.section.has-background-white .has-no-background span:not(.icon) .red-text,
.section.has-background-white .has-no-background strong .red-text,
.section.has-background-white .has-no-background svg .red-text,
.section.has-background-white .has-no-background ul .red-text, .red-text > h1, .red-text > h2, .red-text > h3 ,.red-text > h4, .red-text > .title, .error {
    color: #b21e28;
}
.section.has-background-grey-lighter .has-no-background .subtitle .white-text,
.section.has-background-grey-lighter .has-no-background .title .white-text,
.section.has-background-grey-lighter .has-no-background a:not(.button) .white-text,
.section.has-background-grey-lighter .has-no-background li .white-text,
.section.has-background-grey-lighter .has-no-background p .white-text,
.section.has-background-grey-lighter .has-no-background span:not(.icon) .white-text,
.section.has-background-grey-lighter .has-no-background strong .white-text,
.section.has-background-grey-lighter .has-no-background svg .white-text,
.section.has-background-grey-lighter .has-no-background ul .white-text,
.section.has-background-light .has-no-background .subtitle .white-text,
.section.has-background-light .has-no-background .title .white-text,
.section.has-background-light .has-no-background a:not(.button) .white-text,
.section.has-background-light .has-no-background li .white-text,
.section.has-background-light .has-no-background p .white-text,
.section.has-background-light .has-no-background span:not(.icon) .white-text,
.section.has-background-light .has-no-background strong .white-text,
.section.has-background-light .has-no-background svg .white-text,
.section.has-background-light .has-no-background ul .white-text,
.section.has-background-white .has-no-background .subtitle .white-text,
.section.has-background-white .has-no-background .title .white-text,
.section.has-background-white .has-no-background a:not(.button) .white-text,
.section.has-background-white .has-no-background li .white-text,
.section.has-background-white .has-no-background p .white-text,
.section.has-background-white .has-no-background span:not(.icon) .white-text,
.section.has-background-white .has-no-background strong .white-text,
.section.has-background-white .has-no-background svg .white-text,
.section.has-background-white .has-no-background ul .white-text, .white-text > h1, .white-text > h2, .white-text > h3, .white-text > h4, .white-text > .title {

    color:#fff;
}

    .column {
        padding-top: 0;
    }

    .section .column {
        padding: 0;
        display: flex;
        flex-direction: column;

        &.is-4, &.is-2-desktop {
            padding: 0 1rem;
        }
    }

    .section form .column {
        padding: 0 1rem;
    }

    .sidebar .columns {
        flex-direction: column;
    }


    .white-text, .content h1.white-text, .content h2.white-text, .content h3.white-text, .content h4.white-text, .content h5.white-text, .content h6.white-text {
        color: #fff;
    }

    .red-text, .content h1.red-text, .content h2.red-text, .content h3.red-text, .content h4.red-text, .content h5.red-text, .content h6.red-text {
        color: #b21e28;
    }

    .red-background {
        color: #fff;
        background-color: #b21e28;
    }

    .blue-background {
        color: #fff;
        background-color: $Blue;
    }

    .button.is-link {
        background: #006db6;
    }

    .blog-link {
        font: 'Nunito',Helvetica,Arial,Verdana,sans-serif;
        background-color: #006db6;
        width: auto;
        border-radius: 5px;
        text-align: center;
        padding: 10px;
        margin: 15px auto;
        text-transform: uppercase;
        color: #fff;
        display: block;
        font-weight: 700;
    }

    .blog-preview-image {
        height: auto;
        margin: 0 auto;
        color: #fff;
        position: relative;
        border: none;

        img {
            height: 100%;
        }
    }

    h3.blog-preview-title {
        color: $Blue;
    }

    .blog-preview-content {
        flex-grow: 1;
        font-size: 1.6rem;
        padding-bottom: 20px;
    }

    .blog-preview-date {
        text-align: left;
        font-size: 1rem;
        margin-top: .5rem;
        margin-bottom: .75rem;
    }

    .blog-header-section {
        .blog-preview-date {
            display: none;
        }
    }

    .blog-header-section.no-dates {
        .blog-preview-date {
            display: none;
        }
    }


    .card-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            a {
                align-self: flex-start;
                flex-grow: 1;
                margin-top: auto;
            }
        }
    }
/*
.text-align-Left, .text-align-left, .text-align-right, .text-align-Right, .left-text, .right-text, .subhead {
    text-align: center;

    .title {
        text-align: center;
    }
}
*/
.title, .subhead {
    display: block;
    max-width: 1400px;
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
}
.is-4 .hero-banner .subhead{
    padding-left:0;
    padding-right:0;
}

    @media(min-width:768px){
        .text-align-Left, .text-align-left, .left-text, .subhead {
            .title {
                text-align: left;
            }

            text-align: left;
        }

        .text-align-Right, .text-align-right, .right-text {
            text-align: right;

            .title {
                text-align: right;
            }
        }}

    @media(min-width:1400px){
        .title,.subhead {
            padding-left:0;
            padding-right:30px;
        }
    }


    .sign-up-section {
        .field {
            max-width: 300px;
            margin: 10px auto 0 auto;
        }
    }

    .image {
        background: none;
    }

    .image.is-3by1.content-header {
        padding-top: 0;
    }

    .content-header .title {
        margin-bottom: 20px;
    }


    .has-background-dark {
        background-color: #333 !important;
    }

    .breadcrumb {
        /*border-bottom:1px solid #333;*/
        height: 45px;
        width: 100%;
        margin-top: 20px;

        li {
            float: left;
            padding-left: 10px;
        }

        li + li:before {
            margin-right: 10px;
        }

        a {
            padding: 0;
            color: $Dark;
        }

        li.active {
            font-weight: 800;
        }
    }

    .hero-body {
        align-items: flex-start;
    }
    @media(min-width:$break-medium){
    .text-align-center {
        text-align: center;
    }
    }
    /*.text-align-left {
        text-align: left;
    }

    .text-align-right {
        text-align: right;
    }*/


    .section.hero-banner {
        position: relative;
        height: auto;
        padding: 0;
    }

    .section.hero-banner.is-short {
        @media (min-width:$break-small) {
            min-height: 200px;
        }
    }

    .section.hero-banner.is-medium {
        @media (min-width:$break-small) {
            min-height: 400px;
            padding: 6rem 1.5rem;
            flex-grow:1;
        }
    }

    .section.hero-banner.is-tall {
        @media (min-width:$break-small) {
            min-height: 600px;
        }
    }

    .has-carousel.section.hero-banner.is-medium {
        @media (min-width:$break-medium) {
            min-height: 600px;
        }
    }

    .hero.has-carousel .hero-body {
        margin: 0;
        @media(min-width:$break-large) {
            margin: 0 3rem;
        }
    }

    .section.hero-banner .hero-body {
        z-index: 115;
        width: 100%;
        height: 100%;
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        display: -webkit-box;
        display: flex;
        /* padding: 3rem 0;*/    
    }

    @media print, screen and (min-width: 769px) {
        .hero .hero-body {
            padding-bottom: 0;
            padding-top: 0;
            padding-right: 0;
            padding-left: 0;
        }
    }




    .section {
        padding: 0;
        overflow: hidden;
    }

    .RTE {
        padding: 0 30px;

        ul {
            list-style: disc;
            margin-left: 17px;
        }
    }
.not-sidebar {
    ul {
        list-style: disc;
        margin-left: 37px;

    }
}


    .fitImage, .image .fitImage, .image.is-3by1 .fitImage, .image.is-2by1 .fitImage {
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center center;
        object-position: center center;
        height: 100%;
        width: 100%;
        position:relative;
        @media (min-width:$break-medium) {
            width: auto;
            margin: 0 auto;
            object-fit: contain;
            -o-object-fit: contain;
            position:absolute;
            &.rightImage {
                margin-right: 0;
            }

            &.leftImage {
                margin-left: 0;
            }
        }
    }

    .section {
        .not-sidebar {
            

            @media(min-width:769px) {
                .icon-section .icon-item{
                    margin:30px;
                }
                .columns.single-col {
                    display: block;
                }
            }
        }

        .sidebar {
            @media(min-width:769px) {
                width: 25%;
            }

            .column {
                display: block;
                flex-shrink: 0;
                flex: none;
                flex-grow: 0;
            }

            div {
                font-size: 1.4rem;
            }

            .contact-form input, .contact-form textarea.double-height {
                font-size: 1.6rem;
            }

            .contact-form input[type=radio] {
                display: inline-block;
                width: auto;
                margin: 0 5px;
            }

            .image.is-2by1 {
                padding-top: 0;
                height: 250px;
                margin: 0 40px;

                img {
                    position: relative;
                    padding: 0;
                    object-fit: contain;
                    display: block;
                    flex: none;
                    top: auto;
                    bottom: auto;
                    left: auto;
                    right: auto;
                    width: auto;
                    height: auto;
                }
            }


            .check-col, .check-label-col {
                display: table-cell;
                width: auto;
                padding-bottom: 10px;
            }
        }
    }


    .check-col, .check-label-col {
        display: table-cell;
        width: auto;
        padding-bottom: 10px;
    }


    .columns {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

.icon-section {
    .columns {
        flex-grow: 1;
        margin: 0 auto;
        position: relative;
        width: auto;

        @media(min-width:1024px) {
            max-width: 960px;
        }

        @media(min-width:1216px) {
            max-width: 1152px;
        }

        @media(min-width:1408px) {
            max-width: 1344px;
        }
    }

    .icon-item {
        margin: 0 auto;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        perspective: 1000px; /* Remove this if you don't want the 3D effect */
        @media(min-width:768px) {
            margin: 3rem auto;

            .flip-card {
                transition: transform 0.8s;
                transform-style: preserve-3d;
                position: relative;
                width: 300px;
                height: 400px;
                max-width: 100%;
                backface-visibility: hidden;

                .front-card, .back-card {
                    -webkit-backface-visibility: hidden; /* Safari */
                    backface-visibility: hidden;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }



            &:hover {
                .flip-card {
                    transform: rotateY(180deg);
                }
            }


            .icon {
                font-size: 125px;
                margin-bottom: 20px;
            }

            .buttons:last-child {
                margin-top: auto;
            }

            .front-card {
            }

            .back-card {
                transform: rotateY(180deg);
                padding: 20px;
            }
        }
    }
}


    .testimonials-small {
        font-style: italic;
        font-size: 1.8rem;
        display: block;
        margin: 60px auto;
        margin-top: 60px;
        max-width: 800px;
    }

    .twitter-box {
        max-height: 554px;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-top: 7px;
        box-shadow: 0 0.25rem 0.125rem 0 rgba(0,0,0,.1);
    }

    .narrowText {
        @media (min-width: $break-medium) {
            max-width: 780px;
            margin: 0 auto;
        }
    }

    .fullText {
        @media (min-width: $break-medium) {
            max-width: 1200px;
            margin: 0 auto;
        }
    }

    .blog-section {
        .title {
            margin-bottom: 2rem;
            margin-top: 1rem;
        }
    }


    .blog-categories-header {
        max-width: 420px;
        margin: auto;
        text-align: center;
        font-size: 2.4rem;
        padding: 20px;

        .cta-button-blue {
            padding: 0;
            display: block;

            .hover-box {
                padding: 6px 17px;
            }
        }
    }

    .hover-box {
        font-size: 2.4rem;

        .hover-show {
            display: none;

            .svg-inline--fa {
                font-size: 2.4rem;
                margin-right: 5px;
            }
        }

        .hover-hide {
            display: block;
        }

        &:hover {
            .hover-show {
                display: block;
            }

            .hover-hide {
                display: none;
            }
        }
    }





    input, textarea {
        width: 100%;
        padding: 10px;
        margin: 5px 0;
        border: 1px solid black;

        &.double-height {
            height: 90px;
        }
    }

    input[type=checkbox] {
        width: auto;
        margin-right: 5px;
        margin-top: 2px;
        position: relative;
        top: 2px;
    }

    .remaining-wordcount {
        height: 40px;
        display: block;
        margin: 0;
        line-height: 8px;
        font-size: 1.8rem;
        text-align: right;
    }

    .field-validation-error {
        color: #b21e28;
    }


.grey-box-content {
    background-color: #e8e8e8;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 10px 30px;
    margin-bottom: 20px;

    @media(min-width:$break-medium) {
        padding: 10px;
    }

    a {
        color: $Dark;
    }
}


    .columns:not(.is-desktop).full-width {
        display: block;

        .blog-header-text {
            padding: 30px;
        }
    }


    .blog-readmore {
        display: none;
    }

    .blog-header-section {
        position: relative;

        .blog-readmore {
            position: absolute;
            z-index: 1;
            right: 0;
            left: 0;
            height: 100%;
            background-color: rgba(0,0,0,.5);
            display: flex;
            opacity: 0;
            justify-content: center;
            align-items: center;

            .readmore-button {
                border-radius: 50px;
                color: #fff;
                background-color: $Blue;
                border: 2px solid $White;
                padding: 10px 20px;
                text-transform: uppercase;
            }

            &:hover {
                opacity: 1;
            }
        }

        .image {
            height: auto;
            width: 100%;
            display: flex;
            overflow: hidden;
            justify-content: center;
            margin-bottom: 20px;

            @media(min-width:$break-medium) {
                height: 250px;
                width: 250px;
                max-width: 100%;
            }

            @media(min-width:$break-large) {
                height: 380px;
                width: 400px;
            }

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
                max-width: none;
            }
        }
    }



    .image.blog-half-image, .image.blog-full-image {
        height: 250px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @media(min-width:$break-medium) {
            height: 400px;
            padding-left: 25px;
            margin-top: 20px;
        }
    }

    .image.blog-full-image {
        img {
            object-fit: cover;
            object-position: center;
        }
    }



    .blog-header-text {
        display: flex;
        padding: 0 30px 0 30px;
        flex-direction: column;
        justify-content: center;

        .title {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .subtitle {
            margin-top: 20px;
        }
    }

    .blog-content {
        margin-top: 60px;
        margin-bottom: 60px;

        .button-section {
            padding: 30px;
        }
    }

    .blog-content.full-width {
        margin-top: 0px;
    }

    .blog-headline {
        display: none;
    }

    .horizontal-line {
        margin-left: 10px;
        border-bottom: 2px solid #e8e8e8;
        flex: 1;
    }

    .blog-categories {
        margin-top: 30px;

        @media(min-width:$break-medium){
        &.sidebar {
            padding-left: 20px;
        }

        }
    }

    .blog-list {
        margin-top: 40px;

        .blog-previous {
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 20px;
            border-bottom: 2px solid $Gray;
            margin-bottom: 20px;

            @media(min-width:$break-medium) {
                padding-left: 0;
                padding-right: 0;
            }

            .blog-headline:first-of-type {
                display: none;
            }
        }

        .blog-headline {
            display: flex;
            margin-bottom: 15px;
            align-items: center;
        }

        .blog-pagination {
            width: 100%;

            &.is-right {
                padding: 5px 0;
                text-align: right;

                a {
                    padding: 5px 10px;
                    margin: 0 5px;

                    &.is-light {
                        background-color: #d6d6d6;
                    }
                }
            }
        }

        .subtitle:not(.is-spaced) + .title {
            margin-top: 5px;
        }

        .card.has-equal-height {
            @media(min-width:$break-medium) {
                display: flex;
                box-shadow: none;
                flex-direction: row;
            }

            .image-card {

                .image.blog-preview-image {
                    height: auto;
                    width: 100%;
                }

                @media(min-width:$break-medium) {
                    flex-direction: row;

                    .image.blog-preview-image {
                        width: 200px;
                        height: 200px;

                        img {
                            width: auto;
                            height: 100%;
                        }
                    }
                }
            }

            .card-content {
                padding-top: 20px;
                padding-left: 0;
                padding-right: 0;

                @media(min-width:$break-medium) {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                .content {
                    a {
                        align-self: flex-start;
                        flex-grow: unset;
                    }

                    h3 {
                        font-size: 1.8rem;
                        margin-bottom: 0;
                    }
                }

                .blog-date {
                    margin-bottom: 0;
                }

                .tag {
                    font-size: 1.4rem;

                    a {
                        align-self: center;
                        margin-top: unset;
                    }
                }
            }
        }
    }

    .tag-cat {
        margin-left: 30px;

        .has-spacing-bottom {
            display: inline-block;

            .tag {
                font-size: 1.2rem;
                background-color: unset;
                padding-left: 0;

                a {
                    align-self: center;
                    margin-top: unset;
                    color: $Dark;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
        }
    }

    .card {
        box-shadow: none;

        .card-content {
            .content {
                h3 {
                    font-size: 1.8rem;
                }
            }

            .tag-cat {
                i {
                    display: inline-block;
                }

                .has-spacing-bottom {
                    display: inline-block;
                }
            }

            .tag {
                font-size: 1.2rem;
                background-color: unset;

                a {
                    align-self: center;
                    margin-top: unset;
                    color: $Dark;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
        }
    }
    /*CAROUSEL STYLE*/
    .has-carousel .line {
        width: 300px;
        margin: 5px auto;
        max-width: 100%;
        height: 5px;
        background-color: #fff;
        display: block;
    }




    .title-align-left {
        text-align: left;
    }

    .title-align-right {
        text-align: right;
    }

    .sidebar {
        form {
            @media( min-width:769px) {
                border-left: 2px solid $Gray;
            }

            padding-left: 30px;
            padding-top: 20px;

            .cta-button-blue {
                padding: 10px 30px;

                &:active {
                    color: $Blue;
                }
            }
        }

        .social-section {
            font-family: "Nunito", sans-serif;
            font-weight: 700;
            margin-top: 20px;
            margin-bottom: 20px;
            /*border-top:1px $Gray solid;*/
            border-bottom: 1px $Gray solid;
            padding: 10px 30px;
            @media(min-width:$break-medium){
                padding:10px;
            }
        }

        .social-icons {
            li {
                position: relative;
                display: inline-block;
                margin-right: 10px;
                height: 25px;
                width: 25px;
                margin-top: 8px;

                svg {
                    height: 25px;
                    width: 25px;
                    color: $Blue;
                    border: 1px solid $Blue;
                    border-radius: 50px;
                    padding: 5px;
                }

                a {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
            }
        }
    }



    .columns:last-child {
        margin: 0;
    }

    .columns.is-multiline:last-child {
        margin: 0 auto;
    }


    .microsite-body .hero-banner .hero-body .block.line-above > div > .title {
        border-top: 2px solid #006db6;
        padding-top: 20px;
    }
    /* Colored bar styles -----------------------------------------------------  */
    .colored-bar-rounded {
        position: relative;
        height: 400px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        .colored-bar {
            position: absolute;
            left: -10px;
            right: 20px;
            padding: 100px 20px;
            border-bottom-right-radius: 200px;
            border-top-right-radius: 200px;
            border: 5px solid $Blue;
            transition: all 1s ease;

            @media(min-width:$break-medium) {
                right: 100px;
                padding: 100px;
            }

            .colored-bar-body {
                position: relative;
                line-height: 26px;
                left: 0;
                width: 55%;
                text-align: left;
                font-size: 24px;
                color: #fff;

                @media(min-width:$break-medium) {
                    left: 100px;
                }
            }
        }

        .colored-bar-inset {
            position: absolute;
            right: 0;
            width: 45%;
            font-size: 37px;
            padding: 20px;
            border-bottom-left-radius: 50px;
            border-top-left-radius: 50px;
            background-color: #fff;
            color: $Blue;
            font-weight: bold;
            -webkit-box-shadow: 0px 5px 10px 0px rgba(148,148,148,0.5);
            -moz-box-shadow: 0px 5px 10px 0px rgba(148,148,148,0.5);
            box-shadow: 0px 5px 10px 0px rgba(148,148,148,0.5);
            transition: all 1s ease;

            @media(min-width:$break-medium) {
                width: 40%;
            }
        }

        &:hover {
            .colored-bar {
                &.blue-background {
                    background: #fff;
                    border-color: $Blue;

                    .colored-bar-body {
                        color: $Blue;
                    }
                }
            }

            .colored-bar-inset {
                background-color: $Blue;
                color: #fff;
            }
        }
    }
    /* TABBED section STYLES ---------------------------------------------------------*/
    .tabbed-section, .tab-bar {
        padding-top: 30px;
    }

    .tabbed-section {

        @media(min-width:$break-medium) {
            height: 500px;
        }
    }

    .tab-content {
        min-height: 400px;
    }

    .tab-image-column {
        height: 500px;
        display: flex;
        justify-content: center;

        @media(min-width:$break-medium) {
            display: block;
        }
    }

    .column.tabnav {
        color: $Dark;
        font-weight: 600;
        padding: 10px 20px;
        display: inline-block;
        border-bottom: 2px solid $White;

        &.active {
            color: $Blue;
            font-weight: 600;
            border-bottom: 2px solid $Blue;
        }
    }

    .tab {
        opacity: 0;
        position: absolute;
        margin-top: 20px;
        padding-left: 60px;
        margin-bottom: 20px;

        @media(min-width:$break-medium) {
            width: 57%;
            border-left: 1px solid $Gray;
            margin-bottom: 0;
        }

        &.active {
            opacity: 1;
            display: block;
        }
    }

    .tab-image {
        position: absolute;
        max-height: 500px;
        opacity: 0;
        transition: opacity .25s ease-in-out;
        -moz-transition: opacity .25s ease-in-out;
        -webkit-transition: opacity .25s ease-in-out;

        &.active {
            opacity: 1;
        }
    }
    /* NEWS TICKER ----------------------------------*/
    $duration: 30s;

    @-webkit-keyframes ticker {
        0% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }

        100% {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }
    }

    @keyframes ticker {
        0% {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            visibility: visible;
        }

        100% {
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }
    }

    .news-ticker {
        position: relative;
    }

    .ticker-wrap {
        position: absolute;
        width: 100%;
        overflow: hidden;
        height: 4rem;
        padding-left: 100%;
        box-sizing: content-box;

        .ticker {
            display: inline-block;
            height: 4rem;
            line-height: 4rem;
            white-space: nowrap;
            padding-right: 100%;
            box-sizing: content-box;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            -webkit-animation-name: ticker;
            animation-name: ticker;
            -webkit-animation-duration: $duration;
            animation-duration: $duration;

            &__item {
                display: inline-block;
                padding: 0 2rem;
                font-size: 2rem;
                color: white;

                a {
                    color: #fff;
                }
            }
        }
    }
    /* NEW IMAGE STYLES */
    .image-section {
        height: 100%;

        .columns {
            display: flex;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: relative;
            height: 100%;
            width: 100%;
        }

        .image-full {
            display: flex;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            position: relative;
            height: 100%;
            width: 100%;

            .image {
                display: flex;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                position: relative;
                height: 100%;
                width: 100%;
            }
        }
    }


    .blog-preview-image {
        .fa-play-circle {
            position: absolute;
            align-self: center;
            font-size: 8rem;
        }
    }

    .blog-list {
        .blog-preview-image {
            .fa-play-circle {
                top: 60px;
                left: 60px;
            }
        }
    }

    .video-card {
        .video-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;

            &:hover {
                background: rgba(0,0,0,.5);
            }
        }

        cursor: pointer;
    }

    .blog-header-section {
        .tag-cat {
            display: none;
        }

        .video-card {
            padding-top: 15px;
        }
    }

    .modal {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: none;
        z-index: 1001;

        iframe {
            width: 800px;
            height: 500px;
            max-width: 100%;
        }

        @media(min-width:$break-medium) {
            width: 80%;
        }

        &.active {
            display: flex;
        }
    }

    .modal-background {
        display: none;
        width: 100%;
        position: fixed;
        cursor: pointer;
        top: 0;
        bottom: 0;
        z-index: 1000;

        .fa-times-circle {
            color: #fff;
            font-size: 6rem;
            font-weight: 800;
            position: absolute;
            right: 20px;
            top: 20px;

            &:hover {
                color: #efefef;
            }
        }

        &.active {
            display: block;
        }
    }
    /* PARALLAX HOMEBREW */
    .section.hero-banner.parallax-section {
        &.is-tall {
            min-height: 500px;

            @media(min-width:$break-small) {
                min-height: 600px;
            }
        }
    }

    .parallax {
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .parallax__layer {
    }

    .parallax-base {
        transform: translateZ(-1px);
    }

    .parallax-back {
        background-position: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;

        @media(min-width:1220px) {
            background-attachment: unset;
            background-size: 150%;
        }

        @media(min-width:1440px) {
            background-attachment: unset;
            background-size: 150%;
        }

        @media(min-width:1709px) {
            background-attachment: unset;
            background-size: 120%;
        }
    }
    /* form styles -------------------------------- */
    input, textarea.double-height {
        border-radius: 20px;
        border: 2px #b1b1b1 solid;
        margin: 10px 0;
        resize: none;

        &:focus {
            outline: none;
        }

        &[type="checkbox"] {
            border: 1px solid black;
            padding: 4px;
            font-size: 18px;
            width: 22px;
            height: 22px;
            border-radius: 0;
            vertical-align: middle;
            margin-right: 12px;
            background-color: white;
            box-shadow: none;
            margin-top: 0;
        }
    }

    .fa-chevron-down {
        position: absolute;
        top: 16px;
        right: 20px;
        font-size: 3rem;
        transform: rotate(90deg);
        transition: 500ms ease all;
        color: #b1b1b1;
    }

    .WhatDoYouWant {
        position: relative;

        .fa-chevron-down {
            top: 36px;
        }
    }

    .whoAreYou, .WhatDoYouWant {
        cursor: pointer;
        color: #707070;

        &.active {
            .fa-chevron-down {
                transform: rotate(0deg);
            }
        }
    }

    select {
        width: 100%;
        z-index: 1;
        background: none;
        border-radius: 20px;
        padding: 10px;
        margin: 10px 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;

        &:after {
            position: absolute;
            font-family: 'FontAwesome';
            top: 0;
            right: 10px;
            content: "\f078";
        }

        &:focus {
            outline: none;

            option {
                margin: 20px;
                background: #fff;
                display: block;
            }
        }

        border: 2px #b1b1b1 solid;
    }


    .tns-controls {
        display: none;

        @media(min-width:$break-medium) {
            display: none;
        }
    }
    .tns-nav {
        display:none;
    }
    @media screen and (min-width: 1024px) {
        .section.is-medium {
            padding: 3rem 1.5rem;
        }
    }
    .carousel-section {
        position:relative;
        overflow:visible;
    }
    .my-slider {
        .item{
            .hero-banner{
                .hero-body{padding-left:45px; padding-right:45px; font-family:'Oswald', sans-serif;}
            }
        }
    }

.angleSection {
    position: absolute;
    top: -335px;
    bottom: 0;
    left: -100%;
    height: 700px;
    display: block;
    width: auto;
    align-self: center;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    display: flex;
    align-content: center;
    transition: 1s ease all;
    padding:40px;
    .straightener {
        align-self: center;
    }

    @media(min-width:768px) {
        width: 60%;
        padding-left: 170px;
        transform: skew(-6deg);

        .straightener {
            transform: skew(6deg);
        }
    }
}
.tns-slide-active {
    .angleSection {
        transition: 1s ease all;
        left:-45px;
        @media(min-width:768px) {
            left: -175px;
        }
    }
}


.carousel-bottom-links {
    background: #006DB6;
    Padding: 20px 80px;
    width: 100%;
    color: #fff;
    font-size: 36px;
    font-family: 'Oswald', sans-serif;
    display: block;


    a {
        width: 100%;
        align-self: center;
        color: #fff;
        line-height: 36px;
        display: block;
        padding-bottom: 20px;
        /*&:after {
            content: " ";
            display: block;
            border-bottom: 4px solid #fff;
            width: 45px;
            height: 2px;
            position: relative;
            left: -10px;
            margin-top: 15px;
        }*/
    }

    @media(min-width:1000px) {
        width: 60%;
        display: flex;
        position: absolute;
        right: 0;
        bottom: -20px;
        height: 150px;
        text-align: right;
        justify-content: left;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 10px;
        background: rgba( 0,109,182,.5);

        a {
            width: 30%;
            padding-bottom: 0;
            max-width: 250px;
            text-align: left;
        }
    }

    @media(min-width:1400px) {
        padding: 0 20px 0 50px;
    }
}

.has-carousel {
    .line{display:none;}
}

    .contact-title.title {
        color: $Blue;
        border-top: 2px solid $Blue;
        padding-top: 10px;
        display: inline-block;
        margin: 40px auto 10px auto;
        font-size: 2.4rem;
    }

.section .contact-form {
    max-width: 647px;
    display: block;
    padding: 10px;
    margin: 10px auto;
    font-size: 1.8rem;
    .cta-button-blue {
        margin: 10px auto;
        min-width: 150px;
        display: block;

        &:hover {
            margin: 7px auto 13px auto;
        }
    }

    form .columns {
        display: block;
    }

    form .column {
        padding-left: 0;
        padding-right: 0;
    }

    input, textarea.double-height {
        border: none;
        border-bottom: 1px solid #000;
        border-radius: 0;
        background: none;
        padding: 5px 20px;
        font-size:16px;
        &:focus {
            outline: none;
        }

        &[type="checkbox"] {
            border: 1px solid black;
            padding: 4px;
            font-size: 18px;
            width: 22px;
            height: 22px;
            border-radius: 0;
            vertical-align: middle;
            margin-right: 12px;
            background-color: white;
            box-shadow: none;
            margin-top: 0;
        }
    }

    textarea.double-height {
        border-radius: 8px;
        border: 1px solid #000;
        height: 150px;
        font-size: 16px;
    }

    .fa-chevron-down {
        position: absolute;
        top: 24px;
        right: 20px;
        font-size: 3rem;
        transform: rotate(90deg);
        transition: 500ms ease all;
        color: #b1b1b1;
    }

    .WhatDoYouWant {
        position: relative;

        .fa-chevron-down {
            top: 60px;
        }
    }

    .whoAreYou, .WhatDoYouWant {
        cursor: pointer;
        color: #707070;

        &.active {
            .fa-chevron-down {
                transform: rotate(0deg);
            }
        }
    }

    select {
        width: 100%;
        z-index: 1;
        background: none;
        border-radius: 20px;
        padding: 10px;
        margin: 10px 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        font-size: 2.4rem;

        &:after {
            position: absolute;
            font-family: 'FontAwesome';
            top: 0;
            right: 10px;
            content: "\f078";
        }

        &:focus {
            outline: none;

            option {
                margin: 20px;
                background: #fff;
                display: block;
            }
        }

        border: 2px $Dark solid;
    }
}
.section.split-banner .contact-form {
    max-width: 95%;
    margin: 0;
    padding: 10px 10px 10px 0;

    .columns {
        @media(min-width:$break-large) {
            display: flex;
        }

        .column {
            padding: 0;
            padding-right: 20px;
        }

        label {
            font-size: 12px;
            text-transform: uppercase;
            font-family: 'Roboto';
            font-weight: 600;
        }

        input {
            border: none;
            border-bottom: 1px solid #000;
            border-radius: 0;
            background: none;
        }
    }
}
    .results-list {
        h1 {
            padding-top: 30px;
            padding-left: 10px;
        }

        width: 100%;

        li {
            width: 100%;
            padding: 5px 10px;
            display: block;
        }

        li:nth-child(even) {
            background: #efefef;
        }
    }

    .content-header {
        .hero-body {
            z-index: 200;
        }
    }


    .microsite-body .navbar.has-shadow.is-spaced {
        box-shadow: 0 0 20px 0 #999;
        padding: 12px;
    }

    strong {
        color: unset;
    }

    .section form .column {
        display: block;
    }


input, textarea.double-height {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    background: none;
    padding: 5px 20px;
    /*font-size: 2.4rem;*/
}
textarea.double-height {
    border-radius: 8px;
    border: 1px solid #000;
    height: 150px;
    font-size: 16px;
}

    form button.cta-button-blue {
        display: block;
        font-size: 2.4rem;
        padding: 5px 40px;
    }

    .opt-in {
        font-size: 1.8rem;
        line-height: 2.4rem;
        padding-bottom: 10px;
    }


    #slides a {
        color: #454547;
    }

    .contact-option {
        padding-top: 10px;
    }

    .section .contact-form.showOrder {
        max-width: 100%;
    }

    .section form .columns {
        display: block;
    }

    .section form .showOrder.columns {
        @media (min-width:$break-medium) {
            display: flex;
        }
    }
    /*SITE OVERHAUL SPRING 2020 ----------------------------------------------------- */
    .background-slash {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 50%;
        left: -200px;
        background: #EBEBEB 0% 0% no-repeat padding-box;
        transform: skew(-3deg);
        z-index: 1;
        display:none;
        @media (min-width: 768px){
            display:block;
        }

        @media(min-width:$break-large) {
            right: 41%;
        }
    }


    

    /*.navbar-brand {
        width: 80px;
        margin-left: 0;
        margin-right: 0;
        transition: 500ms ease width;

        @media (min-width:769px) {
            width: 80px;

            &:hover {
                width: 300px;
                transition: 500ms ease width;
            }
        }
    }*/




    .container {
        width:100%;
        @media(min-width:$break-large) {
            width: 100%;
            max-width: none;
        }
    }

    .video-player {
        .video-box {
            width: 100%;
            min-height: 300px;
            min-width: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &.max200h {
                iframe {
                    max-height: 200px;
                }
            }

            &.max300h {
                iframe {
                    max-height: 300px;
                }
            }

            &.max400h {
                iframe {
                    max-height: 400px;
                }
            }

            &.max500h {
                iframe {
                    max-height: 500px;
                }
            }

            &.max600h {
                iframe {
                    max-height: 600px;
                }
            }

            &.max700h {
                iframe {
                    max-height: 700px;
                }
            }
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    .video-section {
        /*&.max200h {
        height:200px;
    }

    &.max300h {
        height:300px;
    }

    &.max400h {
        height:400px;
    }

    &.max500h {
        height:500px;
    }

    &.max600h {
     height:600px;
    }

    &.max700h {
        height:700px;
    }*/
      /*  margin-left: -30px;
        margin-right: -30px;*/

        .video-box {
            position: relative;
            padding-bottom: 56.25%; /* 16:9 */
            height: 0;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }


    .hero-banner.section {
        @media (min-width:767px){
        padding-top: 45px;
        padding-bottom: 45px;
        }
        &.padTB0{
            padding-top:0;
            padding-bottom:0;
        }
        &.padTB15 {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        &.padTB30 {
            padding-top: 30px;
            padding-bottom: 30px;
            padding-left:30px;
            padding-right:30px;
        }

        &.padTB45 {
            padding-top: 90px;
            padding-bottom: 90px;
        }

        &.padTB60 {
            padding-top: 105px;
            padding-bottom: 105px;
        }
    }



    /*.search-section {
        display: none;
    }*/


.hero-banner.section.split-banner {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    .video-player {
        position: relative;
        overflow: hidden;

        @media(min-width:$break-medium) {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .video-box {
            min-height: 300px;

            @media(min-width:$break-medium) {
                width: 100%;
                min-height: 100%;
                min-width: 100%;
                display: block;
                flex: none;
            }
        }

        iframe, object, embed {
            box-sizing: border-box;
            height: 56.25vw;
            left: 50%;
            min-height: 100%;
            min-width: 130%;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            width: 177.77777778vh;

            @media(min-width:1400px) {
                min-width: 100%;
                top: 80%;
            }

            @media(min-width:2000px) {
                min-width: 100%;
                top: 130%;
            }
        }
    }

    .left-col {

        &.is-image {
            padding-bottom: 0;
        }

        overflow: hidden;
        max-width: none;

        .background-slash {
            position: absolute;
            top: 0;
            bottom: 0;
            right: -10%;
            left: auto;
            width: 20%;
            transform: skew(-3deg);
            z-index: 10;
            display: none;
            background-color: #fff;

            &.red-background {
                background-color: #b21e28;
            }

            &.blue-background {
                background-color: #006db6;
            }

            &.white-background {
                background-color: #fff;
            }

            @media(min-width:768px) {
                display: block;
                width: 65%;
            }

            @media(min-width:$break-large) {
                width: 45%;
            }
        }
    }

    .right-col {


        &.is-image {
            padding-bottom: 0;
        }

        overflow: hidden;
        max-width: none;

        .background-slash {
            position: absolute;
            top: 0;
            bottom: 0;
            right: auto;
            left: -10%;
            width: 20%;
            transform: skew(3deg);
            z-index: 10;
            display: none;
            background-color: #fff;

            &.red-background {
                background-color: #b21e28;
            }

            &.blue-background {
                background-color: #006db6;
            }

            &.white-background {
                background-color: #fff;
            }

            @media(min-width:768px) {
                display: block;
                width: 65%;
            }

            @media(min-width:$break-large) {
                width: 45%;
            }
        }
    }
}

.home-page.background-image {
    display: none;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    background-size: cover;

    @media(min-width:$break-medium) {
        display: none;
    }

    &.contact-image {
        @media(min-width:$break-medium) {
            display: block;
            left: auto;
            width: 40%;
            right: 0;
            background-position: top right;
        }
    }
}

.video-player.home-page {
    position: fixed;
    z-index: 0;
    display: none;
    /*top: 0;
    right: 0;
    bottom: 0;
    width: 55%;
    height: 0;
    padding: 0;
    padding-bottom: 56.25%;*/

    .video-box {
        position: fixed;
        top: 0;
        left: auto;
        right:0;
        width: 55%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        overflow: hidden;
        @media(min-width:$break-large){
            width:50%;
        }
    }
    /*iframe {
        height: calc(100% + 250px);
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        pointer-events: none;
        margin-top: -125px;
    }*/
    @media(min-width:768px) {
        display: block;
        height:1000px;
        right:0;
    }

    @media(min-width:$break-large) {
        width: 49%;
    }

    &.is-image {
        padding-bottom: 0;
    }

    overflow: hidden;
    max-width: none;


    iframe, object, embed {
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

    .home-body, .new-footer {
        position: relative;
        z-index: 2;
    }


.new-footer {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
    padding-bottom:30px;
    max-width: 1400px;
    margin: 0 auto;

    @media(min-width:$break-medium) {
        padding-left: 0;
        padding-right: 0;
    }

    @media(min-width:1200px) {
        display: flex;
    }

    .footer-left-col {
        max-width: 100%;
        width: 0;
        padding-left: 7.5px;
        padding-bottom: 20px;


        @media(min-width:1400px) {
            width: 50%;
        }

        .slogan {
            font-family: 'Nunito';
            font-weight: 700;
            text-transform: uppercase;
            font-size: 12px;
        }
    }

    .footer-center-col {
        margin: 0 auto;
    }

    .footer-right-col {
        max-width: 100%;
        width: 100%;
        position: relative;
        font-size: 1.6rem;

        @media(min-width:$break-medium) {
            width: 40%;
        }

        @media(min-width:1200px) {
            padding-left: 20px;
            border-left: 1px solid;
        }

        @media(min-width:1400px) {
            width: 100%;
        }

        .slogan {
            font-family: 'Nunito';
            font-weight: 700;
            text-transform: uppercase;
            font-size: 12px;
        }
    }

    .footer-logo {
        width: auto;
        height: 40px;
        overflow: hidden;
        position: relative;

        img {
            height: 40px;
            max-width: none;
            position: absolute;
        }
    }

    .footer-nav {
        font-family: allotrope, sans-serif;
        width: 100%;

        @media(min-width:768px) {
            display: flex;
            width: auto;
        }

        a {
            color: #333f47;
            font-family: allotrope, sans-serif;
        }

        .bold {
            a {
                font-weight: 600;
                color: #333f47;
            }
        }

        .nav-footer-header {
            a {
                color: #333f47;
            }
        }

        .columns {
            width: 100%;

            @media(min-width:$break-medium) {
                width: 50%;
                display: inline-block;
            }

            @media(min-width:$break-large) {
                display: flex;
            }
        }

        .nav-preview-header {
            position: relative;
            font-size: 21px;
            font-weight: 400;

            .nav-vertical-slash {
                display: none;
            }

            @media(min-width:$break-large) {
                .nav-vertical-slash {
                    height: 65px;
                    display: block;
                }
            }
        }

        .sub-nav {
            min-width: 150px;
            font-weight: 300;
            font-size: 16px;
            line-height: 1.75;
        }
    }
}

.footer {
    font-size: 12px;
    background: none !important;
    color: #979797;

    p {
        color: #979797; 
    }
}


    .gradient-title {
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#006db6+0,2989d8+30,04aaa2+99 */
        background: #006db6; /* Old browsers */
        background: -moz-linear-gradient(left, #006db6 0%, #2989d8 30%, #04aaa2 99%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #006db6 0%,#2989d8 30%,#04aaa2 99%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #006db6 0%,#2989d8 30%,#04aaa2 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006db6', endColorstr='#04aaa2',GradientType=1 ); /* IE6-9 */
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        .title {
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#006db6+0,2989d8+30,04aaa2+99 */
            background: #006db6; /* Old browsers */
            background: -moz-linear-gradient(left, #006db6 0%, #2989d8 30%, #04aaa2 99%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, #006db6 0%,#2989d8 30%,#04aaa2 99%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, #006db6 0%,#2989d8 30%,#04aaa2 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006db6', endColorstr='#04aaa2',GradientType=1 ); /* IE6-9 */
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

.nav-preview-header {
    position: relative;
    font-family: allotrope, sans-serif;
    font-weight: 600;

    .nav-vertical-slash {
        display: none;
    }

    @media(min-width:768px) {
        .nav-vertical-slash {
            display: block;
            height: 65px;
        }
    }
}

    .social-footer, .header-social {
        .social-item {
            display: table-cell;
            padding-right: 5px;
            padding-left: 0;

            a {
                font-size: 16px;
                color: #fff;
            }
        }

        .nav-preview-header {
            font-size: 21px;
            font-weight: 600;
        }
    }
.social-footer {
    .social-item {
        a {
            color: #333f47; 
            font-size: 2.4rem;
        }
    }
}

    .header-social {
        .nav-preview-header {
            font-size: 15px;
        }
    }

.header-social.columns:last-child {
    max-width: 100%;
    line-height: 48px;
    font-size: 16px;
    /*text-transform:uppercase;*/
    display: block;
    text-align: center;
    width: 100%;

    .social {
        display: none;
    }

    @media(min-width:950px) {
        padding-left: 15px;
        margin-left: 15px;
        border-left: 1px solid #4D4D4D;
        display: flex;
        text-align: left;
        /*min-width: 280px;*/
    }
    @media(min-width:950px){
        .social{display:flex;}
    }
    @media(min-width:1400px) {
        padding-left: 30px;
        margin-left: 30px;
    }

    .social {
        margin-left: 20px;
    }

    .nav-preview-header {
        /*font-weight:400;*/
    }
}

    .button-section.columns {
        display: block;

        @media(min-width:1100px) {
            display: flex;
        }
    }



/*    .microsite-body {
        .navbar-end {
            font-family: 'Oswald';
            color: #000;

            a {
                color: #000;
                font-size: 16px;

                &:hover {
                    border-bottom: 1px solid black;
                }
            }
        }

        .navbar.has-shadow.is-spaced {
            margin-left: 60px;
            margin-right: 60px;
        }

        main {
            padding-top: 40px;
        }

        .new-footer {
            background-color: #efefef;
            position: relative;

            .social-footer {
                position: absolute;
                right: 0;
            }

            &.footer-copyright {
                padding-left: 50px;
                padding-top: 0;
            }
        }

        .microsite-content {
            .hero-banner {
                padding-left: 15px;
                padding-right: 15px;

                .hero-banner {
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 0;

                    @media(min-width:768px) {
                        padding-top: 45px;
                    }

                    .hero-banner {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }

        .microsite-content > .hero-banner.section {
            padding-left: 20px;
            padding-top: 0;

            @media(min-width:768px) {
                padding-left: 60px;
            }
        }

        .content-header {
            .hero-body {
                .container {
                    padding-left: 20px;

                    @media(min-width:768px) {
                        padding-left: 60px;
                    }
                }
            }
        }
    }
*/
    .privacy {
        color: #000;
        font-size: 11px;
        margin-top: 10px;
        display: block;
        text-align:right;
        text-transform:uppercase;
    }


    .contact-left {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        z-index: 2;

        .contact-header {
            padding-left: 28px;
            padding-right: 28px;

            .subtitle {
                line-height: 110%;
            }
            @media(max-width:768px){
                text-align:center;
            }
        }

        .contact-intro {
            padding-left: 30px;
        }

        .contact-page.contact-form {
            max-width: 100%;
            margin: 0;
            padding: 10px 10px 10px 30px;

            button.cta-button-blue {
                margin-left: 0;
            }

            .columns {
                @media(min-width:$break-medium) {
                    display: block;
                }

                @media(min-width:$break-large) {
                    display: flex;
                    flex-wrap: wrap;
                }

                .column {
                    padding: 0;
                    padding-right: 20px;
                    padding-top: 15px;
                    position: relative;
                }

                label {
                    font-size: 18px;
                    text-transform: capitalize;
                    font-family: 'Roboto';
                    font-weight: 400;
                }

                input, select {
                    font-size: 1.8rem;
                    border: none;
                    border-bottom: 1px solid #000;
                    border-radius: 0;
                    background: none;
                    padding: 5px 20px;
                }

                select {
                    cursor: pointer;
                    color: #999;
                }

                select + .svg-inline--fa.fa-w-14 {
                    top: unset;
                    right: 40px;
                    float: right;
                    /* this is so when you click on the chevron, your click actually goes on the dropdown menu */
                    pointer-events: none;
                    /* everything after this is just to cover up the original arrow */
                    /* (for browsers that don't support the syntax used above) */
                    background-color: #fff;
                    padding-right: 5px;
                    transform: rotate(0deg);
                    background: none;
                }

                textarea.double-height {
                    border-radius: 8px;
                    border: 1px black solid;
                    height: 150px;
                    font-size: 16px;
                }

                .check-label-col {
                    vertical-align: top;
                }

                .contact-option {
                    @media(min-width:768px) {
                        padding-top: 60px;
                    }
                }

                .remaining-wordcount {
                    font-size: 1.4rem;
                }
            }
        }

        @media print, screen and (min-width: 769px) {
            max-width: 60%;
            padding-left: 30px;
            padding-right: 0;
            padding-top:50px;

            .contact-header {
                padding: 30px;
                padding-bottom: 45px;
                padding-top: 45px;
            }
        }
    }

    .background-slash.contact {
        width: 80%;

        @media(min-width:$break-medium) {
            width: 90%;
        }

        @media(min-width:$break-large) {
            width: 80%;
        }

        @media(min-width:1400px) {
            width: 75%;
        }
    }

    #lottie {
        background-color: #fff;
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        transform: translate3d(0,0,0);
        text-align: center;
        opacity: 1;
        position: fixed;
        top: 0;
        z-index: 1000;

        svg {
            width: 200px !important;
            height: 200px;
        }
    }

body.split-footer {
    height: 100%;

    .social-footer {
        display: flex;
    }

    .footer-center-col {
        /*width: 0;*/
        display: block;

        .footer-nav {
            display: block;
        }

        @media(min-width:$break-medium) {
            .footer-nav {
                display: flex;
            }
        }
    }

    .footer-left-col {
        padding-bottom: 10px;

        .privacy {
            margin-top: 20px;
        }
    }

    .copyright-footer {
        padding-top: 100px;
        padding-bottom: 50px;
    }

    .footer-center-col.copyright {
        margin: 0;
        display: block;
        width: 100%;

        .footer {
            padding: 0;
            padding-left: 6px;
        }


        // @media(min-width:$break-medium) {
        //   width: 49%;
        // }
    }
}
.copyright.has-text-centered {
    text-align: left !important;
    font-weight: 300;
}

@media(min-width:$break-medium) {
    .copyright.has-text-centered {
        text-align: center !important;
    }
}

.come-in {
    transform: translateY(150px);
    animation: come-in 2s ease forwards;
    transition-delay: 1s;
    opacity: 0;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}


@keyframes come-in {
    to {
        transform: translateY(0);
        opacity: 1;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
    }
}

.come-in-left {
    transform: translateX(-350px);
    animation: come-in-left 2s ease forwards;
    transition-delay: 1s;
    opacity: 0;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

@keyframes come-in-left {
    to {
        transform: translateX(0);
        opacity: 1;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
    }
}

.come-in-right {
    transform: translateX(350px);
    animation: come-in-left 2s ease forwards;
    transition-delay: 1s;
    opacity: 0;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
}

@keyframes come-in-right {
    to {
        transform: translateX(0);
        opacity: 1;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
    }
}


.accessibility-btn{
    border:none !important;
}



//mobile fixes

@media(max-width:768px){
.title{
padding-left:16px;
padding-right:16px;
}

}
@media(max-width:768px){
    .hero.hero-banner.section.is-medium.image.contain-bg.is-3by1 {
        min-height:0;
        img.is-background {
            object-fit: contain;
            position: relative;
            height: auto;
        }
    }

    .split-banner{ 
        .section.hero-banner.is-medium 
        {
            min-height:0;
            .hero-body {
        position: relative;

                                        }
    }

    }

}


.static-header {
    position: absolute;
    height:400px;
    width:100%;
    /*overflow:hidden;*/
    img {
        height: 100%;
        width: 100%;
        max-width: none;
        object-fit: cover;
    }

    @media(min-width:$break-medium) {
        height: 600px;
    }

    @media(min-width:$break-large) {
        height: 600px;
    }
}
.section.hero-banner.has-slides{
    height:100%;
    min-height:400px;
}
.slide-section, .slide-section .slide {
    height:auto;
    @media(min-width:$break-medium) {
        height: 600px;
    }

    @media(min-width:$break-large) {
        height: 600px;
        margin-bottom:320px;
    }
}
.slide-section {
    .slide {
        .straightener {
            margin-left: 20px;
            margin-right: 20px;

            @media(min-width:1400px) {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}




.connect-module{
    margin-top:60px;
}

.connect-container {
    max-width: 650px;
    margin: 10px auto;
    padding: 10px 0;

    @media(min-width:$break-medium) {
        display: flex;
        flex-direction: row;
    }

    .connect-left-side {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        @media(min-width:$break-medium) {
            width: 50%;
            border-left: 1px solid #fff;
            border-right: 1px solid #fff;
        }
    }

    .connect-right-side {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        @media(min-width:$break-medium) {
            text-align: left;
        }

        a {
            color: #454547;
        }
    }
}


.search-section{
    margin-right:10px;
}
.search-box {
    button{ color:#fff;
    background: none;
    border: none;
    display:none;
}
    #query{
        display:none;
        position:absolute;
    }
}

.nav-footer-header{
    font-size:16px;
}

.container.blog-post{
    padding-top:50px;
}

.underlined{
    text-decoration:underline;
}

.column.blog-categories{
    justify-content:flex-start;
}

.uwy.uac.userway_p3 .uai{
    bottom:85px !important;
    right:10px;
}

.columns.single-col{
    display:block;
}

.RTE{
    padding:20px;
    @media(min-width:1400px){
        padding:0;
        padding-left:30px;
    }
}

.image.is-2by1{
    padding-top:0;
}
@media(min-width:768px){

    .image.is-2by1 {
        padding-top: 50%;
    }
}

.grecaptcha-badge{
bottom:100px !important;
}


.columns.contain-content{
    max-width:1400px;
    margin:auto;
}
.columns.contain-content.narrow{
    max-width:900px;
}

.align-center{ text-align:center; margin-left:auto;margin-right:auto;}
.align-right{ text-align:right; margin-left:auto;margin-right:0; }
.align-left { text-align:left; margin-left:0;margin-right:auto;}


.columns:not(.is-desktop){
    flex-wrap:wrap;
}

.margin-top-0 { margin-top:0;}
.margin-top-15 { margin-top:15px;}
.margin-top-30 { margin-top:30px;}
.margin-top-45 { margin-top:45px;}
.margin-top-60 { margin-top:60px;}

.padding-30 .section.hero-banner.is-medium .hero-body{ padding:0;}

.section.hero-banner.is-medium .hero-body{ 
    position:relative;
/*    @media(min-width:$break-large){
        position:absolute;
    }*/
}

@media(min-width:$break-medium){
.image-section {
    .is-2by1.image.image-100{ padding-top:100px;}
    .is-2by1.image.image-200{ padding-top:200px;}    
    .is-2by1.image.image-300{ padding-top:300px;}
    .is-2by1.image.image-400{ padding-top:400px;}
    .is-2by1.image.image-500{ padding-top:500px;}
    .is-2by1.image.image-600{ padding-top:600px;}
}
}
@media(min-width:768px){
.spacing-0 { gap:0;
    .column.is-4{ width:33.33%;}
    .column.is-3-desktop{ width:25%;}
    .column.is-6{ width:50%;}
}
.spacing-15{gap:1%;
    .column.is-4{ width:32.6%;}
    .column.is-3-desktop{ width:24.25%;}
    .column.is-6{ width:49.5%; display:flex; flex:auto;
            .hero-banner.section{ flex:auto;}

    }
}
.spacing-30{gap:1%;
            .column.is-3-desktop{ width:24.25%;}
            .column.is-4{ width:32.6%;}
            .column.is-6{ width:49.5%;
                .hero-banner.section{ flex:auto;}
            }
}
.spacing-45{gap:1%;
            .column.is-3-desktop{ width:24.25%;}
            .column.is-4{ width:32.6%;}
            .column.is-6{ width:49.5%;
                .hero-banner.section{ flex:auto;}
            }
}
.spacing-60{gap:2%;
            .column.is-3-desktop{ width:23.5%;}
            .column.is-4{ width:32%;}
            .column.is-6{ width:49%;
                .hero-banner.section{ flex:auto;}

            }
}

}


.contain-bg {
    img.is-background{object-fit:contain;}
}

@media(min-width:768px){
.image.is-2by1.not-background{
    padding-top:0;
}
}

img.not-background {
    width: auto !important;
    height: auto !important;
    margin: 20px auto;
    position: relative !important;
}

.container.contain-this{
    max-width:1400px;

}

.section .column.padding-30{
    padding:30px;
}


.top-center-background, background-position-Top{
    img.is-background{
       object-position:top center;
    }
}
.top-left-background {
    img.is-background {
        object-position: top left;
    }
}
.top-right-background {
    img.is-background {
        object-position: top right;
    }
}
.bottom-center-background, .microsite-body .hero-banner.image.background-position-Bottom {
    img.is-background {
        object-position: bottom center;
    }
}
.bottom-left-background {
    img.is-background {
        object-position: bottom left;
    }
}
.bottom-right-background {
    img.is-background {
        object-position: bottom right;
    }
}

.microsite-body .hero-banner.image.background-position-Center, .background-position-Center {
    img.is-background {
        object-position: center;
    }
}

.column.is-4 .section.hero-banner{
    height:100%;
}

.owl-nav {display:none;}


.hide-background{
    .is-background{
        display:none;
    }
}
@media(min-width:$break-medium){
    .hide-background {
        .is-background {
            display: block;
        }
    }
}